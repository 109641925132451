import React, { FC, useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, FormControl, InputLabel, Select, MenuItem, Alert } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { DocPermissionsRequest, Permission, UserDoc } from '../interfaces/APIResponses';
import { useAuth0 } from "@auth0/auth0-react";
import { useTour } from '@reactour/tour';
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';

interface ShareButtonProps {
    docId: string
}

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export const ShareButton: FC<ShareButtonProps> = ({ docId }) => {

    const [open, setOpen] = useState(false);
    const [docContentPermission, setDocContentPermission] = useState<Permission>(Permission.NONE);
    const [chatPermission, setChatPermission] = useState<Permission>(Permission.NONE);
    const [errorMessage, setErrorMessage] = useState('');
    const { getAccessTokenSilently } = useAuth0();
    const [copySuccess, setCopySuccess] = useState<boolean>(false);
    const { setIsOpen, currentStep, setCurrentStep } = useTour();
    const logBIEvent = useLogBIEvent();

    const fetchInitialPermissions = async () => {
        const token = await getAccessTokenSilently()
        const url = `${BACKEND_API}/docs/${docId}`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const docData: UserDoc = await response.json();

        // If a doc is missing permissions, default to NONE
        if (!docData.docContentPermissionGlobal) {
            docData.docContentPermissionGlobal = Permission.NONE;
        }

        if (!docData.chatPermissionGlobal) {
            docData.chatPermissionGlobal = Permission.NONE;
        }

        setDocContentPermission(docData.docContentPermissionGlobal);
        setChatPermission(docData.chatPermissionGlobal);
    };

    const handleClickOpen = async () => {
        await fetchInitialPermissions();
        setOpen(true);
        setCopySuccess(false);
        setErrorMessage('');

        // Close the tour if it is open.
        setIsOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
        setErrorMessage('');

        // If tour was active, resume.
        if (currentStep !== 0) {
            // Move to next step
            setCurrentStep(currentStep + 1);

            // Log BI event
            logBIEvent(SupportedEvents.TourShareDialogClosed);

            setTimeout(() => {
                setIsOpen(true);
            }, 500); // delay in ms
        }
    };

    const handleShare = async () => {
        try {
            console.log(`Sharing with document content permission: ${docContentPermission} and chat permission: ${chatPermission}`);

            // Log BI event
            logBIEvent(SupportedEvents.ShareButtonClicked);

            await updateDocPermissions(docId, docContentPermission, chatPermission);

            handleClose();
        } catch (error: any) {
            console.error("Error updating document permissions: ", error);
            setErrorMessage(error.message);
        }
    };

    const updateDocPermissions = async (docId: string, docContentPermissionGlobal: Permission, chatPermissionGlobal: Permission) => {

        const token = await getAccessTokenSilently()

        const requestBody: DocPermissionsRequest = {
            docContentPermissionGlobal: docContentPermissionGlobal,
            chatPermissionGlobal: chatPermissionGlobal
        };

        const url = `${BACKEND_API}/docs/${docId}/permissions`;

        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        return data;
    }

    const handleDocPermissionChange = (p: Permission) => {
        setDocContentPermission(p);

        // If document permission is set to None, also set chat permission to None
        if (p === Permission.NONE) {
            setChatPermission(Permission.NONE);
        }
    };

    const handleCopyLink = () => {
        navigator.clipboard.writeText(`${window.location.origin}/doc/${docId}`).then(() => {
            setCopySuccess(true);
        }).catch((err) => {
            console.error("Error copying to clipboard: ", err);
            setErrorMessage('Failed to copy to clipboard.');
        });
    };

    return (
        <div className="share-doc-step">
            <IconButton
                aria-label="share-chat"
                size="small"
                sx={{ alignSelf: 'flex-end' }}
                onClick={handleClickOpen}
            >
                <ShareIcon />
            </IconButton>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle>Share Document</DialogTitle>
                <DialogContent sx={{ m: 1 }}>

                    {errorMessage && <Alert severity='error' sx={{ margin: '1em' }}>{errorMessage}</Alert>}

                    <FormControl fullWidth sx={{ marginTop: '1em' }}>
                        <InputLabel id="docContentPermLabel">Document Access</InputLabel>
                        <Select label="Document Access" labelId='docContentPermLabel' value={docContentPermission} onChange={(e) => handleDocPermissionChange(e.target.value as Permission)}>
                            <MenuItem value={Permission.NONE}>Only Me (Private Document)</MenuItem>
                            <MenuItem value={Permission.READ}>Everyone (Read-Only)</MenuItem>
                            <MenuItem value={Permission.READ_WRITE}>Everyone (Editable by All)</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ marginTop: '2em' }}>
                        <InputLabel id='chatPermLabel'>Chat Access</InputLabel>
                        <Select label="Chat Access" labelId='chatPermLabel' value={chatPermission} onChange={(e) => setChatPermission(e.target.value as Permission)}>
                            <MenuItem value={Permission.NONE}>Only Me (Private Chat)</MenuItem>
                            <MenuItem value={Permission.READ}>Everyone (Read-Only Chat)</MenuItem>
                            <MenuItem value={Permission.READ_WRITE}>Everyone (Participate in Chat)</MenuItem>
                        </Select>
                    </FormControl>


                </DialogContent>
                {copySuccess && <Alert severity='success' sx={{ margin: '1em', marginTop: 0 }}>Link copied to clipboard!</Alert>}
                <DialogActions sx={{ justifyContent: 'space-between' }}>
                    <Button onClick={handleCopyLink}>Copy Link</Button>
                    <div>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleShare}>Share</Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ShareButton;
