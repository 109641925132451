import { keyframes } from '@emotion/react';
import { Button } from "@mui/material";
import { styled } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export const useHighlight = () => {

    const getRandomHighlightColor = () => {
        const greenHighlightColor = '#d5f09c';
        const pinkHighlightColor = '#fad9df';
        const blueishHighlightColor = '#b3f2ff';
        const colors = [greenHighlightColor, pinkHighlightColor, blueishHighlightColor];
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    }

    const color = getRandomHighlightColor();

    // Define your animation
    const glow = keyframes`
      0% { box-shadow: 0 0 5px ${color}, 0 0 10px ${color}, 0 0 15px ${color}, 0 0 20px ${color}; }
      100% { box-shadow: 0 0 10px ${color}, 0 0 20px ${color}, 0 0 30px ${color}, 0 0 40px ${color}; }
    `;

    const backgroundColor = '#009BF3';

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    // Create a styled Button with the animation
    const AnimatedButton = styled(Button)({
        animation: `${glow} 4s infinite`,
        fontSize: isMobile ? '1em' : '1.5em',
        padding: isMobile ? '8px 16px' : '16px 32px',
        margin: '1em',
        textTransform: 'capitalize', // apply text transform to use sentence case
        background: backgroundColor,
        color: '#FCF9F2',
        borderRadius: 50,
        transition: 'transform 0.3s', // this ensures the growth/shrink effect has a smooth transition
        '&:hover': {
            background: backgroundColor, // maintain the same background color on hover
            transform: 'scale(1.1)', // this makes the button grow in size
        }
    });

    return { AnimatedButton, color };
}
