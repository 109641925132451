export interface LicenseResponse {
    licenseKey: string;
    denied: boolean;
    reason: string;
}

export enum AnalysisStepActor {
    System = "system",
    User = "user",
    Assistant = "assistant",
}

export interface AnalysisStepConfig {
    max_tokens?: number;
    temperature?: number;
    top_p?: number;
    presence_penalty?: number;
    frequency_penalty?: number;
    model: string;
}

export interface AnalysisStep {
    stepName: string;
    stepDescription: string;
    actor: AnalysisStepActor;
    config?: AnalysisStepConfig; // If specified at step level, it overrides the default at chain level.
    promptText: string; // We will support some well-known variables here, e.g. {{blockText}}
    promptCompletionSuppressed?: boolean; // If true, we will not send the prompt to AI for completion.
}

export interface AnalysisChain {
    semanticType: string;
    semanticDescription: string;

    steps: AnalysisStep[];
    defaultStepConfig?: AnalysisStepConfig;

    order: number;
}

export interface DocRequest {
    docTitle: string;
    docSummary: string;
    imageContentType: string;
    docScreenshotBase64: string;
    contentType: string;
    docContent: string;
}

export interface DocPermissionsRequest {
    docContentPermissionGlobal: Permission;
    chatPermissionGlobal: Permission;
}

export enum UserDocContentType {
    HTML = 'text/html',
    MARKDOWN = 'text/markdown',
    JSON = 'application/json',
}

export enum Permission {
    NONE = 'none', // DEFAULT
    READ = 'read',
    READ_WRITE = 'read_write',
}

export interface UserDoc {
    _id: string;
    ownerUserId: string;
    title: string;
    summary: string;

    docContentType: string;
    imageContentType: string;

    s3DocContentBucket: string;
    s3DocContentKey: string;

    s3DocThumbnailBucket: string;
    s3DocThumbnailKey: string;

    createdAt: Date;
    updatedAt: Date;

    docContentPermissionGlobal: Permission;
    chatPermissionGlobal: Permission;

    updatedByUserId: string;
}

export type SenderType = 'user' | 'assistant' | 'system';

export interface DocChatMessage {
    _id: string;

    docId: string;

    // senderType is one of 'user', 'assistant', 'system'
    senderType: SenderType;
    
    senderUserId: string;
    senderEmail: string;
    senderPictureUrl: string;

    message: string;

    createdAt: Date;
}

export interface AccountInfo {

    _id?: string;
    userId?: string;
    email?: string;
    pictureUrl?: string;

    // Social media
    linkedInUrl: string;
    twitterUrl: string;

    onboardedOn?: Date;
    createdAt: Date;
    updatedAt: Date;
}

export interface DeleteAccountConfirmation {
    deletedDocsCount: number;
    deletedAccount: boolean;
}

export interface FeedbackRequest {
    text: string;
    starRating: number;
}