import React from 'react';
import { Paper, IconButton, Divider } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import AssistantIcon from '@mui/icons-material/Assistant';
import CodeIcon from '@mui/icons-material/Code';
import CommentIcon from '@mui/icons-material/Comment';
import { Editor } from '@tiptap/react';
import { Badge } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

interface EditorToolbarProps {
    editor: Editor;
    onAnalysisRequested: (text: string) => void;
    onShowCommentsRequested: () => void;
    commentCount: number; // Number of comments for selection.
}

const EditorToolbar: React.FC<EditorToolbarProps> = ({ editor, onAnalysisRequested, onShowCommentsRequested, commentCount}) => {
    return (
        <Paper elevation={3}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                border: (theme) => `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                bgcolor: 'background.paper',
                color: 'text.secondary',
                '& svg': {
                    m: 1,
                },
                '& hr': {
                    mx: 0.5,
                },
            }}
        >
            <Tooltip title="Bold">
                <IconButton onClick={() => editor.chain().focus().toggleBold().run()}>
                    <FormatBoldIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Italic">
                <IconButton onClick={() => editor.chain().focus().toggleItalic().run()}>
                    <FormatItalicIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Strikethrough">
                <IconButton onClick={() => editor.chain().focus().toggleStrike().run()}>
                    <FormatStrikethroughIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Code">
                <IconButton onClick={() => editor.chain().focus().toggleCodeBlock().run()}>
                    <CodeIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Comment">
                <IconButton onClick={() => onShowCommentsRequested()}>
                    <Badge badgeContent={commentCount} color="secondary">
                        <CommentIcon />
                    </Badge>
                </IconButton>
            </Tooltip>
            <Divider orientation="vertical" flexItem />
            <Tooltip title="Analyze">
                <IconButton
                    onClick={() => {
                        // Get selected text
                        const { from, to } = editor.state.selection;
                        const selectedText = editor.state.doc.textBetween(from, to);
                        onAnalysisRequested(selectedText);
                    }}
                >
                    <AssistantIcon />
                </IconButton>
            </Tooltip>
        </Paper>
    );
};

export default EditorToolbar;
