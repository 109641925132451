import { useCallback, useState } from 'react';
import { AccountInfo, DeleteAccountConfirmation } from "../interfaces/APIResponses";
import { useAuth0 } from "@auth0/auth0-react";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export function useGetAccount() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getAccount = useCallback(async (): Promise<AccountInfo | null> => {
        if (!isAuthenticated) {
            console.log("Not authenticated, skipping getting account");
            return null;
        }

        console.log('Getting account using Access Token');

        const accessToken = await getAccessTokenSilently();

        try {
            const response = await fetch(`${BACKEND_API}/account`, {
                headers: {
                    Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
                }
            });

            if (response.status === 404) {
                console.log("Account not found");
                return null;
            }

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: AccountInfo = await response.json();
            console.log("Got account:", data);
            return data;
        } catch (error) {
            console.error("Error getting account:", error);
            throw error;
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return getAccount;
}

export function useCreateUpdateAccount() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const createUpdateAccount = useCallback(async (linkedInUrl: string, twitterUrl: string): Promise<AccountInfo | null> => {
        if (!isAuthenticated) {
            console.log("Not authenticated, skipping creating/updating account");
            return null;
        }

        console.log('Creating/updating account using Access Token');

        const accessToken = await getAccessTokenSilently();

        const accountInfo: AccountInfo = {
            linkedInUrl,
            twitterUrl,
            createdAt: new Date(),
            updatedAt: new Date()
        };

        try {
            const response = await fetch(`${BACKEND_API}/account`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
                },
                body: JSON.stringify(accountInfo)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: AccountInfo = await response.json();
            console.log("Updated account:", data);
            return data;
        } catch (error) {
            console.error("Error creating/updating account:", error);
            throw error;
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return createUpdateAccount;
}

export function useDeleteAccount() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');

    const deleteAccount = useCallback(async (): Promise<DeleteAccountConfirmation | null> => {
        if (!isAuthenticated) {
            console.log("Not authenticated, skipping deleting account");
            return null;
        }

        console.log('Deleting account using Access Token');

        const accessToken = await getAccessTokenSilently();

        try {
            setStatus('pending');
            const response = await fetch(`${BACKEND_API}/account`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data: DeleteAccountConfirmation = await response.json();
            console.log("Deleted account:", data);

            setStatus('success');
            return data;
        } catch (error) {
            console.error("Error deleting account:", error);
            setStatus('error');
            return null;
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return { deleteAccount, status };
}