import { ReactElement, FC, useState, useEffect, useCallback } from "react";
import { Box, Typography, Chip, Alert, CircularProgress } from "@mui/material";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import TextEditor from "../components/TextEditorWithComments";
import ChatPane from "../components/ChatPane";
import AnalysisStates from "../interfaces/AnalysisStates";
import { ThinkerDocLive } from "../interfaces/WebSocketResponses";
import { UserDoc, AnalysisChain, LicenseResponse, DocRequest } from "../interfaces/APIResponses";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useLoadDocument } from "../hooks/docs/useLoadDocument";
import { useCreateDocument } from "../hooks/docs/useCreateDocument";
import { useUpdateDocument } from "../hooks/docs/useUpdateDocument";
import { useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';

const WEBSOCKET_SERVER = process.env.REACT_APP_WEBSOCKET_SERVER;
const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const DEFAULT_DOCUMENT_TITLE = 'Untitled Document';
const DEFAULT_DOCUMENT_CONTENT = '';

const Doc: FC<any> = (): ReactElement => {

    const [htmlContent, setHtmlContent] = useState<string>(DEFAULT_DOCUMENT_CONTENT);
    const [documentTitle, setDocumentTitle] = useState<string>(DEFAULT_DOCUMENT_TITLE);
    const [userDoc, setUserDoc] = useState<UserDoc | null>(null);
    const [licenseKey, setLicenseKey] = useState<string | null>(null);
    const [errorOccured, setErrorOccured] = useState<string | null>(null);
    const [blockClientId, setBlockClientId] = useState<string | null>(null);
    const [blockText, setBlockText] = useState<string>(''); // The text of the block being analyzed.
    const [analysisSteps, setAnalysisSteps] = useState<ThinkerDocLive.AnalysisStepsForBlock | null>(null);
    const [currentStepProgress, setCurrentStepProgress] = useState<ThinkerDocLive.AnalysisStepProgress | null>(null);
    const [lastCompletedAnalysisStep, setLastCompletedAnalysisStep] = useState<ThinkerDocLive.AnalysisStepComplete | null>(null);
    const [availableChains, setAvailableChains] = useState<AnalysisChain[]>([]);
    const [selectedChainName, setSelectedChainName] = useState<string>('basic');
    const { user, isLoading, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isDocLoading, setIsDocLoading] = useState<boolean>(true);
    const [tourActive, setTourActive] = useState<boolean>(false);
    const { setIsOpen, currentStep, setCurrentStep } = useTour();
    const location = useLocation();
    const logBIEvent = useLogBIEvent();

    // Must signin to use the page
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(WEBSOCKET_SERVER!, {
        shouldReconnect: (closeEvent) => true, // Will attempt to reconnect on all close events
        reconnectAttempts: 10, // Number of reconnection attempts
        reconnectInterval: 3000 // The number of milliseconds to delay between reconnection attempts
    });

    const reconnectWebSocket = useCallback(() => {

        const webSocketInstance = getWebSocket();

        if (webSocketInstance !== null) {

            if (webSocketInstance.readyState === WebSocket.OPEN || webSocketInstance.readyState === WebSocket.CONNECTING) {
                // Close the WebSocket connection if it's open or in the process of opening
                webSocketInstance.close();
            }
        }

        // After closing the old connection, a new one will be automatically established.
    }, [getWebSocket]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Connected',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const [analysisState, setAnalysisState] = useState<AnalysisStates>(AnalysisStates.WokeUp);

    /*
    const analysisStateText = {
        [AnalysisStates.WokeUp]: 'Ready',
        [AnalysisStates.ErrorOccured]: 'Error occured',
        [AnalysisStates.GettingSteps]: 'Getting steps',
        [AnalysisStates.StepsReceived]: 'Steps received',
        [AnalysisStates.PerformingStep]: 'Performing step',
        [AnalysisStates.StepProgressUpdate]: 'Step progress update',
        [AnalysisStates.StepCompleted]: 'Step completed',
        [AnalysisStates.AnalysisComplete]: 'Analysis complete',
    }[analysisState];
    */

    const handleStateTransitionError = useCallback(() => {
        console.error("Error while transitioning to state:", analysisState);

        // Go back to initial state. TODO: go back to previous state and recover.
        //setAnalysisState(AnalysisStates.WokeUp);
    }, [analysisState]);

    const getLicense = useCallback(async () => {

        if (!isAuthenticated) {
            console.log("Not authenticated, skipping getting license");
            return;
        }

        console.log('Getting license using Access Token');

        const accessToken = await getAccessTokenSilently();

        // Make a GET to /license endpoint.
        fetch(`${BACKEND_API}/license`, {
            headers: {
                Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data: LicenseResponse) => {
                console.log("Got license:", data);
                if (data.denied) {
                    setErrorOccured(data.reason);
                } else {
                    setLicenseKey(data.licenseKey);
                }
            })
            .catch(error => {
                console.error("Error getting license:", error);
                setErrorOccured("Error getting license.");
            }
            );
    }, [setErrorOccured, setLicenseKey, getAccessTokenSilently, isAuthenticated]);

    const createDocument = useCreateDocument(setUserDoc, setErrorOccured);
    const updateDocument = useUpdateDocument(setUserDoc, setErrorOccured, userDoc)

    const { load: loadDocument } = useLoadDocument(setUserDoc, setDocumentTitle, setHtmlContent, setErrorOccured);

    const { id } = useParams();  // Get the id from the URL parameters

    // Hook for loading the document when the id changes.
    // Hook for loading the document when the id changes.
    useEffect(() => {
        console.log(`Working with doc ID: ${id}`);

        if (isAuthenticated) { // check if the user is authenticated

            if (id === "new") {

                // Create new empty document
                let createDocumentRequest: DocRequest = {
                    docTitle: DEFAULT_DOCUMENT_TITLE,
                    docSummary: '',
                    imageContentType: '',
                    docScreenshotBase64: '',
                    contentType: 'text/html',
                    docContent: DEFAULT_DOCUMENT_CONTENT,
                };

                // Show the loading indicator
                setIsDocLoading(true);

                createDocument(createDocumentRequest).then((doc) => {
                    console.log("Created new document:", doc);

                    // Navigate to the new document.
                    // IMPORTANT: We use replace: true so that the new document replaces the current URL in the browser history.
                    // Otherwise, the user will be able to go back to /doc/new which will create a brand new document! 
                    navigate(`/doc/${doc!._id!}`, { replace: true}); 

                    // Update documentTitle and content.
                    setDocumentTitle(doc!.title);
                    setHtmlContent(DEFAULT_DOCUMENT_CONTENT); // New document starts out empty.

                }).finally(() => {
                    // Hide the loading indicator
                    setIsDocLoading(false);

                    // Log BI event
                    logBIEvent(SupportedEvents.NewDocumentCreated);
                });
            } else if (!userDoc) {

                // Show the loading indicator
                setIsDocLoading(true);

                // Load the document if not already loaded
                loadDocument(id!).finally(() => {

                    // Hide the loading indicator
                    setIsDocLoading(false);

                    // Log BI event
                    logBIEvent(SupportedEvents.DocumentLoaded);
                });
            }
        }

    }, [id, userDoc, createDocument, loadDocument, navigate, isAuthenticated, logBIEvent]);

    const getAvailableChains = useCallback(async () => {
        try {

            if (isAuthenticated) {
                const accessToken = await getAccessTokenSilently();

                // Make a GET to /chains endpoint.
                const response = await fetch(`${BACKEND_API}/chains/details`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
                    }
                });

                const data = await response.json();
                console.log("Got chains basic:", data);

                setAvailableChains(data);

            } else {
                // User is not authenticated, use the public endpoint.
                const response = await fetch(`${BACKEND_API}/chains`);
                const data = await response.json();
                console.log("Got chains with details:", data);

                setAvailableChains(data);

            }

        } catch (error) {
            console.error("Error getting chains:", error);
            setErrorOccured("Error getting chains.");
        }
    }, [setErrorOccured, setAvailableChains, getAccessTokenSilently, isAuthenticated]);

    // Get the chains when the component loads
    useEffect(() => {
        getAvailableChains();
    }, [getAvailableChains]);

    const getAnalysisSteps = useCallback(async (): Promise<ThinkerDocLive.AnalysisStepsForBlock | null> => {
        if (!isAuthenticated) {
            // User is not authenticated, return early
            return null;
        }

        // Transition to state: Getting Analysis Steps.
        setAnalysisState(AnalysisStates.GettingSteps); // Update our state.

        // Select the block to process.
        const selectedBlockClientId = 'entireDoc'; // TODO: update
        setBlockClientId(selectedBlockClientId);
        setBlockText(htmlContent); // Update the block being analyzed.

        // Construct the request.
        const getStepsRequest: ThinkerDocLive.GetAnalysisStepsForBlock = {
            type: 'getAnalysisStepsForBlock',
            licenseKey: licenseKey!,
            blockClientId: selectedBlockClientId,
            blockText: htmlContent,
            analysisChainSemanticType: selectedChainName,
        };

        try {
            const accessToken = await getAccessTokenSilently();

            // Make an HTTP POST to /analyze endpoint.
            const response = await fetch(`${BACKEND_API}/analysis`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`, // Include the access token in the request headers
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(getStepsRequest),
            });

            const data: ThinkerDocLive.AnalysisStepsForBlock = await response.json();
            console.log("Got analysis steps:", data);
            setAnalysisSteps(data);
            setAnalysisState(AnalysisStates.StepsReceived); // Update our state.

            return data;
        } catch (error) {
            console.error("Error getting analysis steps:", error);
            setErrorOccured("Error getting analysis steps.");
            return null;
        }
    }, [htmlContent, licenseKey, setErrorOccured, setAnalysisSteps, setAnalysisState, setBlockText, setBlockClientId, selectedChainName, getAccessTokenSilently, isAuthenticated]);



    const handleWelcomeMessage = useCallback((message: any) => {
        console.log("Welcome message received from server.");

        // Get license.
        getLicense();

    }, [getLicense]);

    const analyzeEntireContent = useCallback((selectedChainName: string) => {

        // If no content, nothing to do.
        if (!htmlContent) {
            console.info("No content, so nothing to do.");
            return;
        }

        // If no license, nothing to do.
        if (!licenseKey) {
            console.info("No license, so can't do anything.");
            return;
        }

        // Log BI event
        logBIEvent(SupportedEvents.AnalyzeEntireContent);

        // Make sure socket is connected.
        reconnectWebSocket();

        // Transition to next state: Getting Analysis Steps.
        setAnalysisState(AnalysisStates.GettingSteps); // Update our state.

        // Select the block to process.
        const selectedBlockClientId = 'entireDoc'; // TODO: update
        setBlockClientId(selectedBlockClientId);
        setBlockText(htmlContent); // Update the block being analyzed.

        // Log the selectedChainName.
        console.info("Selected chain name:", selectedChainName);

        // Send message to get analysis steps.
        const getStepsRequest: ThinkerDocLive.GetAnalysisStepsForBlock = {
            type: 'getAnalysisStepsForBlock',
            licenseKey: licenseKey!,
            blockClientId: selectedBlockClientId,
            blockText: htmlContent,
            analysisChainSemanticType: selectedChainName
        };
        sendMessage(JSON.stringify(getStepsRequest));

        // Log that we are asking server for analysis steps.
        console.info("Got license, so asking server for analysis steps.");

    }, [licenseKey, htmlContent, sendMessage, setAnalysisState, setBlockClientId, reconnectWebSocket, logBIEvent]);

    const handleAnalysisStepsForBlock = useCallback((message: ThinkerDocLive.AnalysisStepsForBlock) => {
        // If we are not in GettingSteps state, then something went wrong. Ignore this message and log.
        if (analysisState !== AnalysisStates.GettingSteps) {
            console.error("Received analysis steps for block, but not in GettingSteps state. Ignoring.");
            return;
        }

        // Update analysis state.
        setAnalysisState(AnalysisStates.StepsReceived);
        setAnalysisSteps(message);

        // Process the first step.
        const firstStep = message.steps[0];

        // Log that we are kicking off analysis.
        console.warn("Kicking off analysis:", firstStep.stepName);

        // Clear out previous step status, since a fresh run is starting.
        setLastCompletedAnalysisStep(null);

        // Clear out current step progress, since a fresh run is starting.
        setCurrentStepProgress(null);

        // Update analysis state.
        setAnalysisState(AnalysisStates.PerformingStep);

        // Send message to perform analysis step.
        const performStepRequest: ThinkerDocLive.PerformAnalysisStep = {
            type: 'performAnalysisStep',
            licenseKey: licenseKey!,
            blockClientId: blockClientId!,
            blockText: blockText,
            analysisChainSemanticType: message.analysisChainSemanticType,
            stepName: firstStep.stepName,
            prevStepResults: [],
            docId: userDoc?._id || "",
        };

        sendMessage(JSON.stringify(performStepRequest));

        // Log that we are asking server to perform analysis step.
        console.info("Got analysis steps, so asking server to perform first step: " + firstStep.stepName);

    }, [analysisState, blockText, blockClientId, licenseKey, sendMessage, setAnalysisState, setAnalysisSteps, setCurrentStepProgress, setLastCompletedAnalysisStep, userDoc]);


    const handleAnalysisStepProgress = useCallback((message: ThinkerDocLive.AnalysisStepProgress) => {
        console.log("Analysis step progress:", message.stepResult.stepName);

        // Update analysis state.
        setAnalysisState(AnalysisStates.StepProgressUpdate);
        setCurrentStepProgress(message);

        // TODO: Show in Chat component.
    }, [setAnalysisState, setCurrentStepProgress]);

    const sendMessageStepName = "$adhoc.userInput";
    const adHocStepPrefix = "$adhoc.";
    const sectionAnalysisStepName = "$adhoc.sectionAnalysis"

    const handleAnalysisStepComplete = useCallback((message: ThinkerDocLive.AnalysisStepComplete) => {
        console.log("Analysis step complete:", message.stepResult.stepName);

        // BEGIN: Band-Aid. The same step is being called twice even though websocket server sends one. useEffect craziness.
        // If we are already in step-completed state, then something went wrong. Ignore this message and log.
        if (analysisState === AnalysisStates.StepCompleted) {
            console.error("Received analysis step complete, but already in StepCompleted state. Ignoring.");
            return;
        }

        // If we are already in analysis-complete state, then something went wrong. Ignore this message and log.
        if (analysisState === AnalysisStates.AnalysisComplete) {
            console.error("Received analysis step complete, but already in AnalysisComplete state. Ignoring.");
            return;
        }
        // END: Band-Aid.

        // Update analysis state.
        setAnalysisState(AnalysisStates.StepCompleted);

        // Update last completed step.
        setLastCompletedAnalysisStep(message);

        // TODO: Update in chat component.

        // If there are more steps, process the next step.
        if (message.nextStepName && !message.nextStepName.startsWith(adHocStepPrefix)) { // Steps that start with are ad-hoc steps and shouldn't be auto-executed.

            // Send message to perform analysis step.
            const performStepRequest: ThinkerDocLive.PerformAnalysisStep = {
                type: 'performAnalysisStep',
                licenseKey: licenseKey!,
                blockClientId: blockClientId!,
                blockText: blockText,
                analysisChainSemanticType: analysisSteps!.analysisChainSemanticType,
                stepName: message.nextStepName,
                prevStepResults: message.prevStepResults,
                docId: userDoc?._id || "",
            };

            sendMessage(JSON.stringify(performStepRequest));

            // Log that we requested next step.
            console.info("Previous step completed, so asking next step: " + message.nextStepName);

        } else {
            // No more steps, so we are done.
            setAnalysisState(AnalysisStates.AnalysisComplete);

            // If the tour is active, move to the next step.
            if (tourActive && currentStep === 0) {
                setCurrentStep(1); // Move to suggested follow-up step

                // Log BI Event.
                logBIEvent(SupportedEvents.TourInitialAnalysisComplete);

                setTimeout(() => {
                    setIsOpen(true);
                  }, 500); // delay in ms

            }

            if (tourActive && currentStep === 1) {
                setCurrentStep(2); // Move to suggested follow-up step

                // Log BI event.
                logBIEvent(SupportedEvents.TourSuggestedFollowUpAnalysisComplete);

                setTimeout(() => {
                    setIsOpen(true);
                  }, 500); // delay in ms

            }

            if (tourActive && currentStep === 2) {
                setCurrentStep(3); // Move to suggested follow-up step

                // Log BI event.
                logBIEvent(SupportedEvents.TourManualQuestionAnalysisComplete);

                setTimeout(() => {

                    // Somehow the scroll to bring texteditor into view doesn't work reliably by Tour, so do it explicitly.
                    const element = document.querySelector('.analyze-section-step');

                    // Use the scrollIntoView function to scroll the element into view
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    }

                    setIsOpen(true);
                  }, 500); // delay in ms
            }

            if (tourActive && currentStep === 3) {
                setCurrentStep(4); // Move to suggested follow-up step

                // Log BI event.
                logBIEvent(SupportedEvents.TourAnalyzeSectionComplete);

                setTimeout(() => {
                    setIsOpen(true);
                  }, 500); // delay in ms
            }

        }
    }, [blockText, analysisState, blockClientId, licenseKey, sendMessage, setAnalysisState, setLastCompletedAnalysisStep, analysisSteps, adHocStepPrefix, userDoc, tourActive, setCurrentStep, currentStep, setIsOpen, logBIEvent]);


    const handleSendUserMessage = useCallback(async (text: string) => {

        // If user is not authenticated, initiate authentication process
        if (!isAuthenticated) {
            console.log("User is not authenticated, initiating authentication process.");
            return;
        }

        // Log BI event
        logBIEvent(SupportedEvents.UserMessageSent);

        // If analysisSteps are not available, it means we aren't ready to analyze a section yet.
        // So kick-off a doc analysis first.
        let steps = analysisSteps;

        if (!steps) {
            console.log("Analysis steps not available, so fetching analysis steps first.");
            steps = await getAnalysisSteps();
        }

        console.log("Sending user message:", text);

        const performStepRequest: ThinkerDocLive.PerformAnalysisStep = {
            type: 'performAnalysisStep',
            licenseKey: licenseKey!,
            blockClientId: blockClientId!,
            blockText: htmlContent, // Could be interesting for a user to chat about a block.
            userInput: text, // Send user input.
            analysisChainSemanticType: steps!.analysisChainSemanticType,
            stepName: sendMessageStepName,
            prevStepResults: lastCompletedAnalysisStep?.prevStepResults || [],
            docId: userDoc?._id || "",
        };

        sendMessage(JSON.stringify(performStepRequest));

        // If tour is open, close it temporarily.
        if (tourActive) {
            setIsOpen(false);
        }

    }, [htmlContent, blockClientId, licenseKey, sendMessage, analysisSteps, lastCompletedAnalysisStep, sendMessageStepName, isAuthenticated, getAnalysisSteps, userDoc, tourActive, setIsOpen, logBIEvent]);

    const handleAnalysisRequested = useCallback(async (chainName: string) => {
        // TODO: implement
        console.log("Analysis requested:", chainName);
        setSelectedChainName(chainName);

        analyzeEntireContent(chainName);

        // Move to step 1 of the tour (analysis results)
        setIsOpen(false);

        // Log BI event
        logBIEvent(SupportedEvents.AnalysisRequested);

    }, [analyzeEntireContent, setSelectedChainName, setIsOpen, logBIEvent]);

    const handleAnalyzeSection = useCallback(async (text: string) => {
        // If user is not authenticated, initiate authentication process
        if (!isAuthenticated) {
            console.log("User is not authenticated, initiating authentication process.");
            return;
        }

        // Log BI event
        logBIEvent(SupportedEvents.AnalyzeSection);

        // Close the tour temporarily.
        if (tourActive) {
            setIsOpen(false);
        }

        // If analysisSteps are not available, it means we aren't ready to analyze a section yet.
        // So kick-off a doc analysis first.
        let steps = analysisSteps;

        if (!steps) {
            console.log("Analysis steps not available, so fetching analysis steps first.");
            steps = await getAnalysisSteps();
        }

        console.log("Analyzing section:", text);

        const performStepRequest: ThinkerDocLive.PerformAnalysisStep = {
            type: 'performAnalysisStep',
            licenseKey: licenseKey!,
            blockClientId: blockClientId!,
            blockText: htmlContent, // Could be interesting for a user to chat about a block.
            userInput: text, // This is how we send stuff in adhoc steps, even for block-analysis.
            analysisChainSemanticType: steps!.analysisChainSemanticType,
            stepName: sectionAnalysisStepName,
            prevStepResults: lastCompletedAnalysisStep?.prevStepResults || [],
            docId: userDoc?._id || "",
        };

        sendMessage(JSON.stringify(performStepRequest));

    }, [htmlContent, blockClientId, licenseKey, sendMessage, analysisSteps, getAnalysisSteps, lastCompletedAnalysisStep, sectionAnalysisStepName, isAuthenticated, userDoc, tourActive, setIsOpen, logBIEvent]);

    const handleLicenseInvalid = useCallback((message: ThinkerDocLive.LicenseInvalid) => {

        console.log("License invalid:", message);

        // Update analysis state to error.
        setAnalysisState(AnalysisStates.ErrorOccured);
        setErrorOccured(message.reason);

    }, [setAnalysisState, setErrorOccured]);

    const handleWebsocketMessage = useCallback((message: any) => {
        try {
            console.log("Message received from server:", message.type);

            switch (message.type) {
                case 'welcome':
                    handleWelcomeMessage(message);
                    break;
                case 'licenseInvalid':
                    handleLicenseInvalid(message);
                    break;
                case 'analysisStepsForBlock':
                    handleAnalysisStepsForBlock(message);
                    break;
                case 'analysisStepProgress':
                    handleAnalysisStepProgress(message);
                    break;
                case 'analysisStepComplete':
                    handleAnalysisStepComplete(message);
                    break;
                case 'errorResponse':
                    handleStateTransitionError();
                    break;
                default:
                    console.error("Unknown message type received from server:", message.type);
            }
        } catch (error) {
            console.error("Error while processing message from server:", error);
            handleStateTransitionError();
        }
    }, [handleWelcomeMessage, handleAnalysisStepsForBlock, handleAnalysisStepProgress, handleAnalysisStepComplete, handleStateTransitionError, handleLicenseInvalid]);

    useEffect(() => {
        if (lastMessage !== null) {
            handleWebsocketMessage(JSON.parse(lastMessage.data));
        }
    }, [lastMessage, handleWebsocketMessage]);

    // check if QSP for "tour" is present. If so, kick off the tour!
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tour = query.get('tour');
    
        if (tour && !isDocLoading && !errorOccured) {
            setTimeout(() => {
                setIsOpen(true);
                setTourActive(true);

                // Log BI event
                logBIEvent(SupportedEvents.TourStarted);
              }, 3000); // delay in ms (2 sec after starter messages for bot are loaded which make the div taller)
      
        }
    }, [location, setIsOpen, isDocLoading, errorOccured, setTourActive, logBIEvent]);
    

    return (
        <Box sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row', // make items align horizontally
            justifyContent: 'center',
            alignItems: 'flex-start', // make items start from the same point
            padding: '1em',
        }}>
            {isMobile &&
                <Alert severity="info">
                    We recommend visiting this page from a laptop or desktop for the best experience.
                </Alert>
            }
            {isDocLoading && <Typography variant="h5" component="div" sx={{ margin: '1em' }}><CircularProgress /></Typography>}
            {(!isMobile) && (!isDocLoading) && (<>
                <Box
                    sx={{
                        flexGrow: 1,
                        width: '50%',
                        marginLeft: '5em',
                        boxShadow: 5, // apply drop shadow
                        backgroundColor: '#fff', // white background
                        padding: '1.0em', // padding inside the box
                        borderRadius: '8px',
                        marginRight: '1em',
                        maxWidth: '60vw',
                        minHeight: '75vh',
                        
                    }}
                >
                    <TextEditor
                        html={htmlContent}
                        documentTitle={documentTitle}
                        onUpdate={(html, thumbnailBase64Png, documentTitle) => {

                            setHtmlContent(html);
                            setDocumentTitle(documentTitle);

                            console.log("HTML:", html);

                            const docRequest: DocRequest = {
                                docTitle: documentTitle,
                                docSummary: "No summary",
                                imageContentType: "image/png",
                                docScreenshotBase64: thumbnailBase64Png,
                                contentType: "text/html",
                                docContent: html,
                            };

                            // If doc is already set, then it's an update. Otherwise, it's a create.
                            if (userDoc) {
                                updateDocument(docRequest);
                            } else {
                                createDocument(docRequest);
                            }

                        }}
                        onAnalysisRequested={(text) => {
                            console.log('Analysis requested for content:', text);
                            handleAnalyzeSection(text);
                        }}
                        currentUserName={user?.name || 'Anonymous'}
                        tourActive={tourActive}
                    />
                </Box>
                <Box sx={{
                    flexGrow: 1,
                    position: 'sticky', // This line makes the position sticky
                    top: '3em', // This line defines the top offset when it becomes sticky
                    boxShadow: 5, // apply drop shadow
                    padding: '0.5em', // padding inside the box
                    borderRadius: '8px',
                    backgroundColor: '#fff', // white background
                    maxWidth: '30vw',
                    minHeight: '75vh', // 'calc(75vh - 3em)', // This line makes the box full height and the 3em is the offset above for top with sticky.
                    display: "flex",
                    flexDirection: "column",
                }}>
                    {errorOccured && <Alert severity="error">{errorOccured}</Alert>}
                    <Typography
                        variant="h5" gutterBottom
                        sx={{
                            padding: '0.5em',
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        SuperWrite AI ✨
                        <Chip label={connectionStatus} size="small" sx={{ fontWeight: 'regular' }} />
                    </Typography>
                    {/* Only load ChatPane if userDoc exists and ID is present */}
                    {userDoc?._id && (
                        <ChatPane
                            analysisState={analysisState}
                            currentStepProgress={currentStepProgress}
                            analysisSteps={analysisSteps}
                            lastCompletedAnalysisStep={lastCompletedAnalysisStep}
                            availableChains={availableChains}
                            onSendMessage={handleSendUserMessage}
                            onAnalysisRequested={handleAnalysisRequested}
                            docId={userDoc._id}
                            tourActive={tourActive}
                        />
                    )}
                </Box>
            </>)}
        </Box>
    );
};

export default Doc;
