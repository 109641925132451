import React, { FC, useState, KeyboardEvent, useEffect, useRef } from "react";
import { Box, Button, Alert, Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, CircularProgress, OutlinedInput, InputAdornment, IconButton, LinearProgress, Select, MenuItem, Divider } from "@mui/material";
import { styled } from "@mui/system";
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import AnalysisStates from "../interfaces/AnalysisStates";
import { ThinkerDocLive } from "../interfaces/WebSocketResponses";
import { AnalysisChain } from "../interfaces/APIResponses";
import StoreIcon from '@mui/icons-material/Store';
import CreateIcon from '@mui/icons-material/Create';
import { UserBubble, BotBubble, InfoBubble } from "./ChatBubbles";
import ChatBubbleMarkdownRenderer from "./ChatBubbleMarkdownRenderer";
import useDocumentChatMessages from "../hooks/useDocumentChatMessages";
import ShareButton from "./ShareButton";
import { useAuth0 } from "@auth0/auth0-react";
import { useTour } from '@reactour/tour';

const QuestionButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    alignSelf: 'flex-start',
    maxWidth: '90%',
    wordWrap: 'break-word',
    textTransform: 'none',  // remove uppercase styling
    textAlign: 'left',
}));

const Progress = styled(LinearProgress)(({ theme }) => ({
    margin: theme.spacing(1),
    maxWidth: '90%',
    color: theme.palette.secondary.light,
}));

interface ChatPaneProps {
    analysisState: AnalysisStates;
    currentStepProgress: ThinkerDocLive.AnalysisStepProgress | null;
    lastCompletedAnalysisStep: ThinkerDocLive.AnalysisStepComplete | null;
    analysisSteps: ThinkerDocLive.AnalysisStepsForBlock | null;
    availableChains: AnalysisChain[];
    docId: string;
    onSendMessage: (message: string) => void;
    onAnalysisRequested: (chainName: string) => void;
    tourActive: boolean;
}

const sendMessageStepName = "$adhoc.userInput";

const ChatPane: FC<ChatPaneProps> = ({ analysisState, currentStepProgress, analysisSteps, availableChains, docId, lastCompletedAnalysisStep, onSendMessage, onAnalysisRequested, tourActive }) => {

    const [newMessage, setNewMessage] = useState('');
    const [selectedChain, setSelectedChain] = useState<string>('basic');
    const { chatMessages, isLoading, error, addMessage, addBotMessage, deleteAllMessages, refetch } = useDocumentChatMessages(docId);
    const { user } = useAuth0();
    const { setIsOpen } = useTour();

    const handleSendMessage = async () => {
        // Clear the input field
        setNewMessage('');

        // Add message
        await addMessage(newMessage);

        // Send the message to AI
        onSendMessage(newMessage);
    };

    const handleSuggestedQuestionClick = async (text: string) => {

        // If a tour is active, close it temporarily.
        setIsOpen(false);

        // Save the message to the history
        await addMessage(text);

        // Call the parent component to send the message to the server.
        onSendMessage(text);

    }

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    const handleDeleteMessages = async () => {
        // Delete all messages
        await deleteAllMessages();
        // Refetch messages
        refetch();
    };

    const handleSelectedChainChange = (event: any) => {
        setSelectedChain(event.target.value as string);
    };

    const [inProgressStepDescription, setInProgressStepDescription] = useState<string | null>(null);

    useEffect(() => {
        if (analysisState === AnalysisStates.StepProgressUpdate && currentStepProgress) {
            // Find the currentStepProgress in analysisSteps
            const currentStep = analysisSteps?.steps.find(step => step.stepName === currentStepProgress.stepResult.stepName);
            if (currentStep) {
                console.log("Current step description:", currentStep.stepDescription);
                setInProgressStepDescription(currentStep.stepDescription);
            }
        }
    }
        , [analysisState, currentStepProgress, analysisSteps]);

    const getMessageMarkdownWithStepDescription = (stepName: string, message: string, stepDescription: string | null) => {
        if (stepDescription && stepName !== sendMessageStepName) {
            // TODO: Use promps in Markdown render to set the fonts for various levels of headings.
            return `**${stepDescription}** \n\n${message}`;
        }
        else {
            return message;
        }
    }

    useEffect(() => {
        if ((analysisState === AnalysisStates.StepCompleted || analysisState === AnalysisStates.AnalysisComplete) && lastCompletedAnalysisStep) {

            // Find the lastCompletedAnalysisStep in analysisSteps
            const lastCompletedStep = analysisSteps?.steps.find(step => step.stepName === lastCompletedAnalysisStep.stepResult.stepName);
            if (lastCompletedStep) {

                const stepCompletedMessage = getMessageMarkdownWithStepDescription(lastCompletedAnalysisStep.stepResult.stepName, lastCompletedAnalysisStep.stepResult.result, lastCompletedStep.stepDescription);

                addBotMessage(stepCompletedMessage);
            }
        }
    }
        , [analysisState, lastCompletedAnalysisStep, analysisSteps, addBotMessage]);

    function splitQuestionsFromText(text: string) {
        const questionRegex = /Q\d*?:\s([\s\S]*?)(?=\nQ\d*?:|$)/g;

        // Extracting questions
        const questions = [];
        let match;
        while ((match = questionRegex.exec(text)) !== null) {
            // Push the question (match[1]) to the array, but remove any trailing whitespaces
            questions.push(match[1].trim());
        }

        // Extracting non-question text
        const nonQuestionText = text.replace(questionRegex, "").trim();

        return { nonQuestionText, questions };
    }

    const endOfMessagesRef = useRef<HTMLDivElement | null>(null);
    const draftMessageRef = useRef<HTMLDivElement | null>(null);

    // Effect to scroll to end of messages
    useEffect(() => {
        // If we scroll with just one message, default, then the page scrolls by default which feels weird.
        if (endOfMessagesRef.current !== null && chatMessages.length > 1) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages]);

    // Effect to scroll to draft message (this causes some jerkiness though!)

    useEffect(() => {
        if (draftMessageRef.current !== null) {
            draftMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [currentStepProgress]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [isFirstMessageVisible, setIsFirstMessageVisible] = useState(false);
    useEffect(() => {
        setTimeout(() => {
          setIsFirstMessageVisible(true);
        }, 500); // delay in ms
      }, []);

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
        }}>
            <Box display="flex" justifyContent="space-between">
                <ShareButton
                    docId={docId}
                />
                <IconButton
                    aria-label="delete"
                    size="small"
                    onClick={handleClickOpen}
                    sx={{ alignSelf: 'flex-end' }}
                    disabled={chatMessages.length === 0}
                >
                    <DeleteIcon />
                </IconButton>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Delete confirmation"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to delete the messages?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => {
                            handleDeleteMessages();
                            handleClose();
                        }} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

            <Box 
                className="analysis-results-step"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'scroll',
                    scrollbarWidth: 'none', // For Firefox
                    '&::-webkit-scrollbar': { // For Chrome, Safari, and Opera
                        display: 'none',
                    },
                    flexGrow: 1, // This will push the next Box to the bottom
            }}>
                {isLoading && (<CircularProgress />)}
                {error && (<Alert severity="error">{error}</Alert>)}
                {chatMessages.length === 0 && isFirstMessageVisible && (<>
                    <BotBubble sx={{
                        animation: 'fade-in 1s'
                    }}>
                        <ChatBubbleMarkdownRenderer>
                            Hello! 👋 I'm SuperWrite AI, your dedicated guide to help you navigate your documents effectively. I'm capable of performing a wide array of tasks. If you have questions about the contents of your document, need to analyze the text in different ways, or want to reference information from the internet or your previous documents, I'm here to help!
                        </ChatBubbleMarkdownRenderer>
                    </BotBubble>
                    <BotBubble sx={{
                        animation: 'fade-in 1s'
                    }}>
                        <ChatBubbleMarkdownRenderer>
                            In the dropdown menu below, you'll find several analysis types available. Choose the one that best suits your needs and simply click "Analyze" to get started. I'm here to make your experience with your documents more efficient and insightful. Feel free to reach out if you need assistance!
                        </ChatBubbleMarkdownRenderer>
                    </BotBubble></>
                )}
                {chatMessages.map((msg, index) => {
                    switch (msg.senderType) {
                        case 'user':
                            return (
                                <UserBubble
                                    key={index}
                                    message={msg.message}
                                    senderEmail={user && msg.senderUserId === user.sub ? "You" : msg.senderEmail}
                                    senderPictureUrl={msg.senderPictureUrl}
                                    createdAt={msg.createdAt}
                                />
                            );
                        case 'assistant':
                            const { nonQuestionText, questions } = splitQuestionsFromText(msg.message);
                            return (
                                <BotBubble key={index}
                                    className={questions.length > 0 ? "analysis-followup-step" : ""}
                                >
                                    <ChatBubbleMarkdownRenderer>{nonQuestionText}</ChatBubbleMarkdownRenderer>
                                    {questions.map((question, qIndex) =>
                                        <QuestionButton key={qIndex} variant="outlined" color="primary" onClick={() => handleSuggestedQuestionClick(question)}>{question}</QuestionButton>
                                    )}
                                </BotBubble>
                            );
                        default:
                            return (
                                <InfoBubble key={index}>
                                    {msg.message}
                                </InfoBubble>
                            );
                    }
                })}

                {analysisState === AnalysisStates.StepProgressUpdate && currentStepProgress && (
                    <>
                        <BotBubble>
                            <ChatBubbleMarkdownRenderer>{getMessageMarkdownWithStepDescription(currentStepProgress.stepResult.stepName, currentStepProgress.stepResult.result, inProgressStepDescription)}</ChatBubbleMarkdownRenderer>
                        </BotBubble>
                        <Progress />
                        <div ref={draftMessageRef} />
                    </>
                )}


            </Box>
            {chatMessages.length > 0 && <div ref={endOfMessagesRef} />}
            <Divider />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: 1,
                }}
            >
                <Box
                    sx={{
                        display: 'flex', // make sure the select and button are in the same row
                        alignItems: 'center',
                        marginBottom: '0.5em', // add some margin at the bottom
                        width: '100%', // to take full width of the parent
                    }}
                >
                    {/* Show the available descriptions of available chains in a Select */}
                    {availableChains && (
                        <>
                            <Select
                                sx={{ marginRight: '0.5em' }}
                                value={availableChains.length > 0 ? selectedChain : ""}
                                onChange={handleSelectedChainChange}
                                variant="outlined"
                                size="small"
                            >
                                {availableChains.map((chain, index) => (
                                    <MenuItem key={chain.semanticType} value={chain.semanticType}>{chain.semanticDescription}</MenuItem>
                                ))}
                                <Divider />
                                <MenuItem
                                    key="store"
                                    value="store"
                                    disabled
                                >
                                    <Box display="flex" alignItems="center">
                                        <StoreIcon />
                                        <Box mr={1} />
                                        Analyst Store (Coming Soon)
                                    </Box>
                                </MenuItem>
                                <MenuItem
                                    key="diy"
                                    value="diy"
                                    disabled
                                >
                                    <Box display="flex" alignItems="center">
                                        <CreateIcon />
                                        <Box mr={1} />
                                        DIY (Coming Soon)
                                    </Box>
                                </MenuItem>
                            </Select>
                            <Button className="analyze-step" variant="outlined" color="primary" onClick={() => onAnalysisRequested(selectedChain)}>Analyze</Button>
                        </>
                    )}
                </Box>
                <OutlinedInput
                    className="manual-question-step"
                    placeholder="Ask a question about this doc"
                    multiline
                    fullWidth
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={handleKeyPress}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                disabled={newMessage === ''}
                                aria-label="toggle password visibility"
                                onClick={handleSendMessage}
                                onMouseDown={handleSendMessage}
                                edge="end"
                            >
                                <SendIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </Box>
        </Box>
    );
};

export default ChatPane;
