import React from 'react';
import { MuiMarkdown, getOverrides } from 'mui-markdown';

type MarkdownRendererProps = {
  children: string;
};

const markdownOverrides: any = {
    ...getOverrides(), // This will keep the other default overrides.
    h1: {
      component: 'p',
      props: {
        style: { fontSize: '1.8em', fontWeight: 'bold' }, // modify the fontSize according to your needs.
      } as React.HTMLProps<HTMLParagraphElement>,
    },
    h2: {
      component: 'p',
      props: {
        style: { fontSize: '1.6em', fontWeight: 'bold' }, // modify the fontSize according to your needs.
      } as React.HTMLProps<HTMLParagraphElement>,
    },
    h3: {
      component: 'p',
      props: {
        style: { fontSize: '1.4em', fontWeight: 'bold' }, // modify the fontSize according to your needs.
      } as React.HTMLProps<HTMLParagraphElement>,
    },
    h4: {
        component: 'p',
        props: {
            style: { fontSize: '1.2em', fontWeight: 'bold' }, // modify the fontSize according to your needs.
        } as React.HTMLProps<HTMLParagraphElement>,
    },
    h5: {
        component: 'p',
        props: {
            style: { fontSize: '1.1em', fontWeight: 'bold' }, // modify the fontSize according to your needs.
        } as React.HTMLProps<HTMLParagraphElement>,
    },
    h6: {
        component: 'p',
        props: {
            style: { fontSize: '1em', fontWeight: 'bold'}, // modify the fontSize according to your needs.
        } as React.HTMLProps<HTMLParagraphElement>,
    },
};

const ChatBubbleMarkdownRenderer: React.FC<MarkdownRendererProps> = ({ children }) => {
  return (
    <MuiMarkdown overrides={markdownOverrides}>
      {children}
    </MuiMarkdown>
  );
};

export default ChatBubbleMarkdownRenderer;
