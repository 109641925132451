//import React from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { routes as appRoutes } from "./routes";
import { ThemeOptions } from "@mui/material/styles";
import Footer from "./components/Footer";
import AppBar from "./components/Appbar";
import './App.css';
import "cal-sans";
import { TourProvider } from '@reactour/tour';
import { steps } from './utils/onboardingSteps';

function App() {
  // define theme

  const themeOptions: ThemeOptions = {
    palette: {
      mode: 'light',
      primary: {
        main: '#009bf3',
      },
      secondary: {
        main: '#603be0',
      },
      background: {
        default: '#fcf9f2',
      },
    },
    shape: {
      borderRadius: 12,
    },
  };

  const theme = createTheme(themeOptions);

  const BoxBackground = () => {

    return {
      // backgroundImage: `url(/rolling_hills.svg)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      backgroundColor: '#FFF',
    };

  }

  return (
    <ThemeProvider theme={theme}>
      <TourProvider
        badgeContent={({ totalSteps, currentStep }) => currentStep + 1 + "/" + totalSteps} 
        styles={{
          popover: (base) => ({
            ...base,
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.background.default,
          }),
          badge: (base) => ({ ...base, backgroundColor: theme.palette.secondary.main }),
          dot: (base) => ({ ...base, backgroundColor: theme.palette.secondary.main, color: theme.palette.secondary.main }),
          maskArea: (base) => ({ ...base, rx: theme.shape.borderRadius } as any),
          
        }}
        steps={steps} scrollSmooth disableDotsNavigation>
        <CssBaseline />
        <Box display="flex" flexDirection="column" minHeight="100vh"
          style={BoxBackground()}
        >
          <Router>
            { /* <Navbar /> */}
            {<AppBar />}
            <Box flexGrow={1}>
              <Routes>
                {appRoutes.map((route) => (
                  <Route
                    key={route.key}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
              </Routes>
            </Box>
            <Footer />
          </Router>
        </Box>
      </TourProvider>
    </ThemeProvider>
  );

}

export default App;
