import React from 'react';
import { Box, List, ListItem, Typography, Divider } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

const formatTimeAgo = (d: any) => (d ? `${formatDistanceToNow(new Date(d))} ago` : null);

const CommentList: React.FC<{
    allComments: Array<any>;
}> = ({ allComments }) => {
    return (<>
        {allComments.length > 0 && (<Divider role="presentation" sx={{ width: '100%', marginTop: 2 }}>
            <Typography variant="body2" color="text.secondary">
                Comments
            </Typography>
        </Divider>)}
        <List sx={{ width: '100%', marginTop: 1 }}>
            {allComments.map((comment, i) =>
                comment.jsonComments.comments.map((jsonComment: any, j: number, arr: any[]) => (
                    <React.Fragment key={`${i}_${j}_external_comment`}>
                        <ListItem sx={{ p: 0 }}>
                            <Box sx={{ width: '100%', marginBottom: 2 }}>
                                <Typography variant="subtitle1" component="div">
                                    <strong>{jsonComment.userName}</strong>
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {formatTimeAgo(jsonComment.time)}
                                </Typography>
                                <Typography variant="body1"
                                    sx={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}
                                >
                                    {jsonComment.content}
                                </Typography>
                            </Box>
                        </ListItem>
                        {j !== arr.length - 1 && <Divider light />}
                    </React.Fragment>
                ))
            )}
        </List>
    </>
    );
};

export default CommentList;
