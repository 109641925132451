import React, { FC, ReactElement, useState } from "react";
import { TextField, Typography, Box, Stack, Alert } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useHighlight } from "./AnimatedGlowingButton";
import { useGetAccount, useCreateUpdateAccount } from '../hooks/accountHooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';

const Waitlist: FC = (): ReactElement => {
    const theme = useTheme();
    const { AnimatedButton, color } = useHighlight();

    const getAccount = useGetAccount();
    const createUpdateAccount = useCreateUpdateAccount();

    const [twitterHandle, setTwitterHandle] = useState('');
    const [linkedInProfileUrl, setLinkedInProfileUrl] = useState('');

    const [updateApplied, setUpdateApplied] = useState(false);

    const [errorOccured, setErrorOccured] = React.useState<string>('');

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const logBIEvent = useLogBIEvent();

    React.useEffect(() => {
        const checkAndCreateAccount = async () => {
            try {
                const account = await getAccount();
                if (account) {
                    // Set the Twitter and LinkedIn handles if account exists
                    setTwitterHandle(account.twitterUrl);
                    setLinkedInProfileUrl(account.linkedInUrl);
                } else {
                    // Create new account with empty fields
                    await createUpdateAccount('', '');
                }
            } catch (error: any) {
                // Handle the error
                console.error("Error occurred while checking or creating account:", error);
                setErrorOccured('Error while getting account information. Please try again later.');
            }
        };
        checkAndCreateAccount();
    }, [getAccount, createUpdateAccount, setErrorOccured]); // Ensure effect runs when these dependencies change

    const handleUpdateClick = async () => {
        try {
            if (twitterHandle && linkedInProfileUrl) {
                await createUpdateAccount(linkedInProfileUrl, twitterHandle);
                setUpdateApplied(true);

                // Log event
                logBIEvent(SupportedEvents.WaitlistSocialHandlesUpdated);
            }
        } catch (error: any) {
            // Handle the error
            console.error("Error occurred while updating account:", error);
            setErrorOccured('Error while updating account information. Please try again later.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                maxWidth: '50rem',
                margin: 'auto',
                padding: isMobile ? theme.spacing(2) : 0,
            }}
        >
            {errorOccured && <Alert severity='error' sx={{ margin: '1em' }}>{errorOccured}</Alert>}
            <Typography variant="h1" component="div" gutterBottom>
                Hi! 👋
            </Typography>
            <Typography variant="h4" component="div" gutterBottom style={{ backgroundColor: color, marginLeft: '0.167em' }}>
                Thanks for your interest in SuperWrite!
            </Typography>
            <Typography variant="h4" component="div" gutterBottom>
                We are in our alpha phase and are carefully onboarding users to ensure the best possible experience.
            </Typography>
            <Typography variant="body1" component="div" gutterBottom>
                We ask for your Twitter and LinkedIn handles to better understand our community.
            </Typography>

            <Box component="form" sx={{
                margin: theme.spacing(4),
                width: '50%',
            }} >
                <Stack direction="column" spacing={2}>
                    <TextField label="Twitter Handle" variant="outlined" onChange={(e) => {
                        setTwitterHandle(e.target.value);
                        setUpdateApplied(false);
                    }} value={twitterHandle} />
                    <TextField label="LinkedIn Profile URL" variant="outlined" onChange={(e) => {
                        setLinkedInProfileUrl(e.target.value);
                        setUpdateApplied(false);
                    }} value={linkedInProfileUrl} />

                    {updateApplied && (
                        <Alert severity="success">
                            Your information has been updated.
                        </Alert>
                    )}

                    <Box>
                        <AnimatedButton onClick={handleUpdateClick}>
                            Update
                        </AnimatedButton>
                    </Box>
                    {/* 
                    <Button variant="contained" color="secondary">
                        Skip the waitlist with a $25 payment
                    </Button>
                    */}
                </Stack>
            </Box>
        </Box>
    );
};

export default Waitlist;
