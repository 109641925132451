
import { DocRequest } from "../interfaces/APIResponses";
const sampleDocBody = "<h2>Abstract</h2><p>This proposal aims to address the universally pertinent matter of enhancing life on Earth. The present scenario is far from perfect, thus it's time for drastic changes.</p><h2>Introduction</h2><p>In this ever-evolving cosmos, Earth stands out as a unique habitat for life. However, the quality of life on Earth could significantly improve. Herein, we present a plan to revolutionize life on Earth. </p><h2>The Proposals</h2><h3>Solution 1: Increase Happiness</h3><p>Our first proposal is simple - Increase happiness. By increasing happiness, we decrease sadness. The precise method of doing so is yet to be determined, but the goal is clear. </p><h3>Solution 2: Eliminate Waste</h3><p>Eliminating all waste will clean up our planet. The specifics of how we will achieve a zero waste society is still under research, but once achieved, it will lead to a cleaner, greener Earth.</p><h3>Solution 3: Enhance Love</h3><p>Promoting universal love is the key. The more love there is in the world, the fewer problems we will face. The means to quantify and distribute this love is in the pipeline.</p><h2>Conclusion</h2><p>In conclusion, by increasing happiness, eliminating waste, and enhancing love, life on Earth will significantly improve. The specifics may be unclear, but the destination is certain - a better Earth. We have utmost faith in these questionable but ambitious proposals, the implementation of which will surely usher in Earth 2.0.</p><h2>Future Work</h2><p>More concrete steps and feasibility studies are planned to work out the kinks in the proposal. However, we believe that these are the broad steps that humanity needs to take to ensure a future worth living. </p>";

export const sampleDoc: DocRequest = {
    docTitle: "Earth 2.0: A Paradigm Shift",
    docSummary: '',
    imageContentType: '',
    docScreenshotBase64: '',
    contentType: 'text/html',
    docContent: sampleDocBody,
};

export default sampleDoc;
