import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface LicenseResponse {
  denied: boolean;
}

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export const useLicenseCheck = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [licenseDenied, setLicenseDenied] = useState<boolean | null>(null);
  const [errorOccurred, setErrorOccurred] = useState<string | null>(null);
  const [checkingLicense, setCheckingLicense] = useState<boolean>(false);

  const getLicense = useCallback(async () => {
    if (!isAuthenticated) {
      console.log("Not authenticated, skipping getting license");
      return;
    }

    console.log('Getting license using Access Token');

    setCheckingLicense(true);

    const accessToken = await getAccessTokenSilently();

    fetch(`${BACKEND_API}/license`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    .then(response => {
      if (response.status === 403) {
        return {
          denied: true
        };
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data: LicenseResponse) => {
      console.log("Got license:", data);
      setLicenseDenied(data.denied);
    })
    .catch(error => {
      console.error("Error getting license:", error);
      setErrorOccurred("Error getting license. Please retry later.");
      setLicenseDenied(true);
    }).finally(() => {
      setCheckingLicense(false);
    });
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    getLicense();
  }, [getLicense]);

  return { licenseDenied, errorOccurred, checkingLicense };
};
