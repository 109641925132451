import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { DocRequest, UserDoc } from "../../interfaces/APIResponses";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export const useCreateDocument = (setUserDoc: Function | null, setErrorOccured: Function) => {
    
    const { getAccessTokenSilently } = useAuth0();

    return useCallback(async (docToCreate: DocRequest): Promise<UserDoc | null> => {

        const accessToken = await getAccessTokenSilently();

        console.log('Creating document using Access Token', docToCreate)

        // Make an HTTP POST to /docs endpoint.
        return fetch(`${BACKEND_API}/docs`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the request headers
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(docToCreate)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data: UserDoc) => {
                console.log("Created/updated document:", data);
                if (setUserDoc) {
                    setUserDoc(data);
                }
                return data;
            })
            .catch(error => {
                console.error("Error creating/updating document:", error);
                setErrorOccured("Error creating/updating document.");
                return null;
            }
            );
    }, [getAccessTokenSilently, setUserDoc, setErrorOccured]);
};
