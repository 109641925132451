import React from 'react';
import { Container, Box } from '@mui/material';
import NormalMarkdownRenderer from '../components/NormalMarkdownRenderer';

function TermsOfUse() {
    return (
        <Container>
            <Box
                sx={{
                    flexGrow: 1,
                    marginLeft: {
                      xs: '1em',  // for screens 0px and up
                      sm: '2em',  // for screens 600px and up
                      md: '3em',  // for screens 900px and up
                      lg: '5em',  // for screens 1200px and up
                      xl: '5em',  // for screens 1536px and up
                    },
                    boxShadow: 5, // apply drop shadow
                    backgroundColor: '#fff', // white background
                    padding: {
                      xs: '1em',  
                      sm: '2em',  
                      md: '3em',  
                      lg: '5em',  
                      xl: '5em',  
                    }, // padding inside the box
                    borderRadius: '8px',
                  }}
                >
                <NormalMarkdownRenderer>{`# SuperWrite Terms of Service
_Last updated: Jul 27, 2023_
## Acceptance of Terms
By accessing and using the SuperWrite application ("the Service"), currently operated by the entity responsible for SuperWrite (”the Operator”), you are agreeing to be bound by these Terms of Service. If you do not agree to these terms, please do not use the Service.

## Use of the Service
You agree to use the Service only for purposes permitted by these Terms of Service and any applicable laws, regulations, or generally accepted practices in the relevant jurisdictions. SuperWrite reserves the right to refuse service to anyone for any reason at any time.

## User Content
As a document analysis service, we respect and protect your privacy. You retain full ownership of any intellectual property rights that you hold in the content that you create or own ("User Content"). When you upload or submit content to our Services, you give SuperWrite permission to analyze and store this content on your behalf. We will not use, host, reproduce, modify, create derivative works, communicate, publish, publicly perform, publicly display, or distribute your User Content for any other purposes. To learn how we protect your privacy, read our [Privacy Policy](/privacy).

## Termination
You may terminate your account at any time by going to 'Account Settings' and choosing 'Delete My Account'. Please be aware that upon termination of your account, all data associated with it will be deleted.

## Subscription Services
Certain parts of our Services are offered on a subscription basis. If you choose to subscribe, you agree to the following:

- **Billing:** Subscriptions are billed in advance on a recurring basis according to the plan you select. Your subscription will auto-renew under the same terms unless you or we cancel it.
- **Cancellation:** You can cancel your subscription at any time. No refunds will be provided for the remaining period of your current subscription, but you will retain access to paid services until the end of the subscription period.
- **Payment:** You agree to provide accurate billing information and to keep it up to date. Payments are facilitated through third-party processors. By using these services, you also agree to the terms and conditions of the Payment Processors.
- **Modification of Fees:** We may modify subscription fees at any time, with changes taking effect at the end of your current subscription period. You will be notified in advance of any changes, and your continued use of the Services will signify your agreement to the new fees.
- **Refunds:** Except where required by law, paid subscription fees are non-refundable.

## Modification and Termination of Services
We are constantly innovating and changing our Services, and we may add or remove functionalities or features, and we may suspend or stop a Service altogether.

## Disclaimer
The Services are provided on an "as-is" basis without any warranties, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. SuperWrite, its affiliates, and third-party service providers disclaim all warranties to the fullest extent permitted by law.

## Limitation of Liability
You expressly understand and agree that SuperWrite shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including damages for loss of profits, goodwill, use, data or other intangible losses.

## Indemnity
You agree to indemnify and hold harmless SuperWrite and its affiliates, officers, directors, employees, agents, and third-party service providers from any losses, liabilities, damages, claims, and expenses, including legal fees, arising out of or related to your use of the Services or any violation of these Terms.

## Privacy and Personal Information
For information about SuperWrite’s data protection practices, please read our [Privacy Policy](/privacy). This policy explains how we treat your personal information and protect your privacy when you use our Services.

## Changes to these Terms
We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. Please review these Terms of Service regularly. Your continued use of the Services after the effectiveness of such changes will constitute acceptance of and agreement to any such changes.

## Contact us
If you have any questions about these terms, you can reach us at [hello@getsuperwrite.com](mailto:hello@getsuperwrite.com).

`}</NormalMarkdownRenderer>
            </Box>
        </Container>
    );
}

export default TermsOfUse;
