import React, { FC, ReactElement } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

const LoginButton: FC = (): ReactElement => {
  const { loginWithRedirect } = useAuth0();

  return <Button variant="text" size="small" color="primary" endIcon={<LoginIcon />} onClick={() => loginWithRedirect()}>Log In</Button>;
};

export default LoginButton;
