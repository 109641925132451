import React, { FC, ReactElement } from "react";
import { Box, Container, Grid, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export const Footer: FC = (): ReactElement => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        paddingTop: "3rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
          <Box sx={{fontFamily: 'Cal Sans', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
  <Typography variant="body1">SuperWrite</Typography>
  <Typography variant="body2" sx={{marginLeft: '0.25rem'}}>
    (c) {`${new Date().getFullYear()} All Rights Reserved`}
  </Typography>
</Box>
          </Grid>
          <Grid item xs={12}>
          <Typography variant="body2">
              <Link component={RouterLink} to="/privacy" sx={{color: 'inherit', textDecoration: 'underline'}}>
                Privacy Policy
              </Link>
              {" | "}
              <Link component={RouterLink} to="/terms" sx={{color: 'inherit', textDecoration: 'underline'}}>
                Terms of Use
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
