export enum AnalysisStates {
    WokeUp,
    ErrorOccured,
    GettingSteps,
    StepsReceived,
    PerformingStep,
    StepProgressUpdate,
    StepCompleted,
    AnalysisComplete
}

export default AnalysisStates;