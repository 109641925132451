import Home from "./pages/Home";
import MyDocs from "./pages/MyDocs";
import Doc from "./pages/Doc";
import Account from "./pages/Account";
import Privacy from "./pages/Privacy";
import TermsOfUse from "./pages/Terms";
import Waitlist from "./pages/Waitlist";

// other
import {FC} from "react";

// interface
interface Route {
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>
}

export const routes: Array<Route> = [
    {
        key: 'home-route',
        title: 'Home',
        path: '/',
        enabled: true,
        component: Home
    },
    {
        key: 'mydocs-route',
        title: 'My Docs',
        path: '/mydocs',
        enabled: true,
        component: MyDocs
    },
    {
        key: 'doc-route',
        title: 'Doc',
        path: '/doc/:id',
        enabled: true,
        component: Doc
    },
    {
        key: 'account-route',
        title: 'Account',
        path: '/account',
        enabled: true,
        component: Account
    },
    {
        key: 'privacy-route',
        title: 'Privacy',
        path: '/privacy',
        enabled: true,
        component: Privacy
    },
    {
        key: 'terms',
        title: 'Terms',
        path: '/terms',
        enabled: true,
        component: TermsOfUse
    },
    {
        key: 'waitlist',
        title: 'Waitlist',
        path: '/waitlist',
        enabled: true,
        component: Waitlist,
    }
]