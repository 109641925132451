import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LoginButton from './LoginButton';
import Profile from './Profile';
import { useAuth0 } from "@auth0/auth0-react";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import { useLicenseCheck } from '../hooks/useLicenseCheck';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FeedbackForm from './FeedbackForm';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { useTour } from '@reactour/tour';

export default function ButtonAppBar() {

  const { isAuthenticated } = useAuth0();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { licenseDenied } = useLicenseCheck();
  const { setIsOpen } = useTour();

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = async (itemTitle: string) => {
    handleClose();
  };

  const menuItems = [
    {
      key: 'home-route',
      title: 'Home',
      path: '/',
      enabled: true,
      authRequired: false,
      waitlistersAllowed: true,
    },
    {
      key: 'mydocs-route',
      title: 'My Docs',
      path: '/mydocs',
      enabled: true,
      authRequired: true,
      waitListersAllowed: false,
    },
    {
      key: 'doc-route',
      title: 'Create New Doc',
      path: '/doc/new',
      enabled: true,
      authRequired: true,
      waitlistersAllowed: false,
    },
    {
      key: 'account-route',
      title: 'Account',
      path: '/account',
      enabled: true,
      authRequired: true,
      waitListersAllowed: true,
    }
  ];

  // Filter out the menu items based on authentication status and waitlist status
  let filteredMenuItems = menuItems.filter(item => item.enabled && (!item.authRequired || (item.authRequired && isAuthenticated)));
  if (licenseDenied) { // license is denied to waitlisted users. So, filter out the menu items that are not allowed for waitlisters.
    filteredMenuItems = filteredMenuItems.filter(item => item.waitlistersAllowed);
  }

  const [openFeedback, setOpenFeedback] = React.useState(false);

  const handleOpenFeedback = () => {

    // Close the tour!
    setIsOpen(false);

    setOpenFeedback(true);
  };

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };

  return (
    <Box sx={{ marginBottom: '2em' }}>
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          {filteredMenuItems && filteredMenuItems.length > 1 && (<>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={open}
              onClose={handleClose}
            >
              <Paper sx={{ width: 320, maxWidth: '100%' }} elevation={0}>
                {filteredMenuItems
                  .map(item => (
                    <MenuItem key={item.key} onClick={() => handleItemClick(item.title)} component={Link} to={item.path}>
                      {item.title}
                    </MenuItem>
                  ))}
              </Paper>
            </Menu></>)}
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit', display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, color: (theme) => theme.palette.text.primary }}>
              <img src="/logo.png" alt="SuperWrite Logo" style={{ height: '32px', marginRight: '10px' }} />
              <Typography variant="h6" component="div" sx={{ fontFamily: 'Cal Sans' }}>
                SuperWrite
              </Typography>
            </Box>
          </Link>

          <Dialog open={openFeedback} onClose={handleCloseFeedback}>
            <DialogTitle>Submit Feedback</DialogTitle>
            <DialogContent>
              <FeedbackForm />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFeedback}>Close</Button>
            </DialogActions>
          </Dialog>

          {isAuthenticated ? (
            <>
              <Button className="feedback-step"
                size="small" color="secondary" onClick={handleOpenFeedback} sx={{ marginRight: '1em' }} endIcon={<ChatBubbleIcon />}>
                Feedback
              </Button>
              <Profile />
            </>
          ) : (
            <LoginButton />
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
