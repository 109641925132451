import { useAuth0 } from "@auth0/auth0-react";
import React, { FC, ReactElement, useState } from "react";
import { Box, Tooltip, Avatar, CircularProgress, Badge, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';

const Profile: FC = (): ReactElement | null => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin } as any);
  };

  if (isLoading) {
    return <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}><CircularProgress /></Box>;
  }

  return (
    isAuthenticated && user ? (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Badge badgeContent="🚀" 
              overlap="circular"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}>
                <Tooltip title={user.email} placement="bottom">
              <Avatar src={user.picture} alt={user.name} sx={{ cursor: 'pointer', boxShadow: 2 }} onClick={handleClick} />
                </Tooltip>
            </Badge>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>
              <Link to="/account" style={{ textDecoration: 'none', color: 'inherit' }}>Account</Link>
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
      </Box>
    ) : null
  );
};

export default Profile;
