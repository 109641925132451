import { useEditor, EditorContent, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { Markdown } from 'tiptap-markdown';
import { Box, Paper, Chip, Input } from '@mui/material';
import { Comment } from './extensions/Comment';
import { v4 as uuidv4 } from 'uuid';
import React, { useState, useEffect, useRef } from 'react';
import CommentInput from './CommentInput';
import CommentList from './CommentList';
import EditorToolbar from './EditorToolbar';
import { useDebouncedCallback } from "use-debounce";
import { useTour } from '@reactour/tour';
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';

// 1. The custom Comment extension extends the base functionality of the Tiptap editor to support comments.
//
// 2. Comments are initially embedded directly in the HTML content of the editor as data attributes (data-comment). 
//    Each comment includes a unique identifier (UUID), an array of comments, and other associated information.
//
// 3. State management:
//    - `allComments`: All comments in the document.
//    - `activeCommentsInstance`: The currently active comment.
//    - `commentText`: The new comment being entered.
//
// 4. `findCommentsAndStoreValues` function: Finds all comment markers in the document (span elements with a data-comment attribute), 
//     parses the comment data from the attribute, and stores it in the `allComments` state.
//
// 5. `setCurrentComment` function: Checks if the current selection in the editor is a comment. If it is, it parses the comment data 
//     and sets it as the `activeCommentsInstance`. This function is called when the selection in the editor changes (onSelectionUpdate) or the 
//     editor content updates (onUpdate).
//
// 6. `setComment` function: Adds a new comment to the existing comments of the current instance. If necessary, it generates a new UUID, 
//     and sets the updated comment data on the editor selection using the `setComment` command (likely provided by the custom Comment extension).
//
// 7. Rendering Comments:
//    - Inside the BubbleMenu: User can add a new comment to the current comment instance (using data from `activeCommentsInstance`).
//    - In a list next to the editor: All comments are displayed (using data from `allComments`).
//
// 8. `toggleCommentMode` function: Switches between the editor mode and the comment mode. When the comment mode is on, the editor is made non-editable, 
//    allowing users to navigate and interact with comments without modifying the text.

interface CommentInstance {
  uuid?: string
  comments?: any[]
}

type TextEditorProps = {
  html: string;
  documentTitle: string;
  onUpdate: (html: string, thumbnailBase64Png: string, documentTitle: string) => void; // this function takes two string arguments and doesn't return anything
  onAnalysisRequested: (text: string) => void;
  currentUserName: string;
  tourActive: boolean;
};

const TextEditor: React.FC<TextEditorProps> = ({ html, documentTitle, onUpdate, onAnalysisRequested, currentUserName, tourActive }) => {
  
  const { setIsOpen, currentStep, setCurrentStep } = useTour();
  const myRef = useRef(); // Used for taking a screenshot of the contents.

  const logBIEvent = useLogBIEvent();

  const debouncedUpdates = useDebouncedCallback(async (editor) => {

    setSaveStatus("Saving...");

    const html = editor.getHTML();

    // Screenshots are too large right now. Need to figure out how to scale them. TODO.
    // const screenshotPng = await takeScreenshot();
    const screenshotPng = "";

    await onUpdate(html, screenshotPng, localDocumentTitle);

    setSaveStatus("Saved");

  }, 500);


  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: true,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: true,
        },
      }),
      Markdown,
      Comment,
    ],
    content: html,
    autofocus: "end",
    editable: true,
    onCreate: ({ editor }) => {
      // Set focus at the end of the document.
      // editor.commands.focus();
    },
    onUpdate: async ({ editor }) => {

      setSaveStatus("Unsaved");

      await debouncedUpdates(editor);

      findCommentsAndStoreValues();

      setCurrentComment(editor);
    },
    onSelectionUpdate({ editor }) {
      setCurrentComment(editor);

      // setIsTextSelected(!!editor.state.selection.content().size)
    },
  });

  useEffect(() => {
    console.log("Content prop has changed:", html);
    // Calling setContent causes the focus to be set in the document to the end which is super-obnoxious.
    // Do not call setContent if the content hasn't changed.

    const currentHtmlInEditor = editor?.getHTML();

    if (currentHtmlInEditor !== html) {
      // Log a message
      console.log("Setting content in editor based on updated content");
      editor?.commands.setContent(html);

      // Process the comments that just came in.
      findCommentsAndStoreValues();

      if (editor) {
        setCurrentComment(editor);
      }

    } else {
      console.log("Content in editor is the same as the updated content. Not setting content in editor.");
    }
  }, [html, editor]);

  const [activeCommentsInstance, setActiveCommentsInstance] = React.useState<CommentInstance>({});

  const [allComments, setAllComments] = React.useState<any[]>([]);

  const findCommentsAndStoreValues = () => {
    const proseMirror = document.querySelector('.ProseMirror');

    const comments = proseMirror?.querySelectorAll('span[data-comment]');

    const tempComments: any[] = [];

    if (!comments) {
      setAllComments([])
      return;
    }

    comments.forEach((node) => {
      const nodeComments = node.getAttribute('data-comment');

      const jsonComments = nodeComments ? JSON.parse(nodeComments) : null;

      if (jsonComments !== null) {

        // Currently there's a weirdness in how comments are saved in that, if a paragraph has multiple <tags>,
        // then the comment gets saved agains each tag separately. So bold, itatlic, etc. with a paragraph will
        // have comments repeated. As a result, when someone clicks on the highlight to view comments, the comments
        // can get repeated. This is a hack to prevent that from happening.

        // Check if the comment already exists in the array. If it does, don't add it again.
        // Check by checking the UUID and the time field.

        
        const existingComment = tempComments.find((comment) => 
          comment.jsonComments.uuid === jsonComments.uuid && 
          comment.jsonComments.comments[0].time === jsonComments.comments[0].time);

        if (!existingComment) {
          tempComments.push({
            node,
            jsonComments,
          });
        }
        
      }
    });

    setAllComments(tempComments)
  };

  const setCurrentComment = (editor: any) => {
    const newVal = editor.isActive('comment');

    if (newVal) {
      // setTimeout(() => setShowCommentMenu(newVal), 50);
      // setShowAddCommentSection(!editor.state.selection.empty)

      const parsedComment = JSON.parse(editor.getAttributes('comment').comment);

      parsedComment.comment = typeof parsedComment.comments === 'string' ? JSON.parse(parsedComment.comments) : parsedComment.comments;

      setActiveCommentsInstance(parsedComment)
    } else {
      setActiveCommentsInstance({})
    }
  };

  const handleAddComment = (newCommentText: string) => {
    if (!newCommentText.trim().length) return;

    // If tour is active, close it temporarily.
    if (tourActive) {
      // Move to next step.
      setCurrentStep(currentStep + 1);

      // Log BI event.
      logBIEvent(SupportedEvents.TourAddCommentDone);

      setTimeout(() => {
        setIsOpen(true);
      }, 500); // delay in ms
    }

    const activeCommentInstance: CommentInstance = JSON.parse(JSON.stringify(activeCommentsInstance));

    const commentsArray = typeof activeCommentInstance.comments === 'string' ? JSON.parse(activeCommentInstance.comments) : activeCommentInstance.comments;

    if (commentsArray) {
      commentsArray.push({
        userName: currentUserName,
        time: Date.now(),
        content: newCommentText,
      });

      const commentWithUuid = JSON.stringify({
        uuid: activeCommentsInstance.uuid || uuidv4(),
        comments: commentsArray,
      });

      // eslint-disable-next-line no-unused-expressions
      editor?.chain().setComment(commentWithUuid).run();
    } else {
      const commentWithUuid = JSON.stringify({
        uuid: uuidv4(),
        comments: [{
          userName: currentUserName,
          time: Date.now(),
          content: newCommentText,
        }],
      });

      // eslint-disable-next-line no-unused-expressions
      editor?.chain().setComment(commentWithUuid).run();
    }
  };

  //useEffect((): any => setTimeout(findCommentsAndStoreValues, 100), []);

  // Extract the UUID of the selected comment
  const activeCommentUUID = activeCommentsInstance.uuid || "";

  // Filter all comments for the currently selected comment
  const commentsInSelectedParentComment = allComments.filter((comment) => comment.jsonComments.uuid === activeCommentUUID);

  // Filter all comments for the currently selected comment
  const selectedCommentParent = allComments.find((comment) => comment.jsonComments.uuid === activeCommentUUID);

  // Determine comment count of the selected comment instance
  const commentCountUnderSelectedParentComment = selectedCommentParent ? selectedCommentParent.jsonComments.comments.length : 0;

  const [showComments, setShowComments] = React.useState(false);

  const handleShowCommentsRequested = () => {
    setShowComments(true);
  }

  const [saveStatus, setSaveStatus] = useState("Saved");

  const [localDocumentTitle, setLocalDocumentTitle] = useState(documentTitle);

  // Update localDocumentTitle when documentTitle changes.
  useEffect(() => {
    setLocalDocumentTitle(documentTitle);
  }
    , [documentTitle]);



  return (
    <Box
      p={1} position="relative">

      <Box position="absolute" top={0} right={0}>
        <Chip label={saveStatus} size="small" />
      </Box>

      {editor && <BubbleMenu editor={editor} tippyOptions={{
        duration: 100,
        onHide: () => setShowComments(false), // Reset state so that we don't automatically show comments in the future.
      }}>

        <EditorToolbar
          editor={editor}
          onAnalysisRequested={onAnalysisRequested}
          onShowCommentsRequested={handleShowCommentsRequested}
          commentCount={commentCountUnderSelectedParentComment}
        />

        {showComments && (
          <Paper elevation={3} style={{ padding: '1rem', width: '20rem' }}>
            <CommentInput onAddComment={handleAddComment} />
            <CommentList allComments={commentsInSelectedParentComment} />
          </Paper>
        )}

      </BubbleMenu>}

      <Box sx={{ padding: '3em' }} ref={myRef}>
        <Input value={localDocumentTitle}
          sx={{
            fontSize: '2em', // apply h1 fontSize of .ProseMirror h1
            fontWeight: 'bold', // apply h1 fontWeight of .ProseMirror h1
            fontColor: 'rgba(0, 0, 0, 0.87)', // apply h1 fontColor of .ProseMirror h1      
            '&::before': { borderBottom: 'none' },
            width: '100%'
          }}
          placeholder='Document title'
          onChange={async (e) => {
            setLocalDocumentTitle(e.target.value);
            await debouncedUpdates(editor);
          }}

        />
        <EditorContent 
          className="analyze-section-step"
          editor={editor} />
      </Box>

    </Box>
  );
};

export default TextEditor;