import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { DocChatMessage } from '../interfaces/APIResponses';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const useDocumentChatMessages = (docId: string) => {
  // The chat messages state.
  const [chatMessages, setChatMessages] = useState<DocChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const { getAccessTokenSilently } = useAuth0();

  const fetchChatMessages = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(`${BACKEND_API}/docs/${docId}/chat`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      setChatMessages(responseData);  // set your data into state
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, docId]);

  const addMessage = useCallback(async (message: string) => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${BACKEND_API}/docs/${docId}/chat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ message }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData: DocChatMessage = await response.json();

      setChatMessages(prevMessages => [...prevMessages, responseData]);
    } catch (e: any) {
      setError(e.message);
    }
  }, [getAccessTokenSilently, docId]);

  const addBotMessage = useCallback(async (message: string) => {

    const timeStamp = new Date().toISOString();

    const botMessageLocal: DocChatMessage = {
      _id: timeStamp,
      docId,
      senderType: 'assistant',
      senderUserId: 'assistant',
      senderEmail: '',
      senderPictureUrl: '',
      message,
      createdAt: new Date(),
    };

    setChatMessages(prevMessages => [...prevMessages, botMessageLocal]);

  }, [docId]);

  const deleteMessage = useCallback(async (messageId: string) => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${BACKEND_API}/docs/${docId}/chat/${messageId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setChatMessages(prevMessages => prevMessages.filter(message => message._id !== messageId));
    } catch (e: any) {
      setError(e.message);
    }
  }, [getAccessTokenSilently, docId]);

  const deleteAllMessages = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      const response = await fetch(`${BACKEND_API}/docs/${docId}/chat`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setChatMessages([]);
    } catch (e: any) {
      setError(e.message);
    }
  }, [getAccessTokenSilently, docId]);

  useEffect(() => {
    fetchChatMessages();
  }, [fetchChatMessages]);

  return { chatMessages, isLoading, error, refetch: fetchChatMessages, addMessage, addBotMessage, deleteMessage, deleteAllMessages };
};

export default useDocumentChatMessages;
