import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useState } from "react";
import { UserDoc } from "../../interfaces/APIResponses";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export const useLoadDocument = (setUserDoc: Function, 
    setDocumentTitle: Function, setDocContent: Function, setErrorOccurred: Function) => {

    const { getAccessTokenSilently } = useAuth0();
    const [status, setStatus] = useState('idle');

    return {
      load: useCallback(async (idToLoad: string) => {
        setStatus('loading');

        const accessToken = await getAccessTokenSilently();

        console.log("Loading document:", idToLoad);

        // Make a GET to /docs endpoint.
        fetch(`${BACKEND_API}/docs/${idToLoad}`, {
            headers: {
                Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data: UserDoc) => {
                console.log("Got document:", data);
                setUserDoc(data);

                setDocumentTitle(data.title);

                // Get the doc content by issuing a GET against /docs/:docId/contents endpoint.
                fetch(`${BACKEND_API}/docs/${idToLoad}/contents`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
                    }
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }

                        const contentType = response.headers.get("Content-Type");

                        if (contentType && contentType.includes("application/json")) {
                            // It's JSON.
                            return response.json();
                        } else {
                            // It's likely plain text.
                            return response.text();
                        }
                    }
                    )
                    .then((data: any) => {

                        console.log("Got document content:", data);
                        setDocContent(data);
                        setStatus('success');
                    }
                    )
                    .catch(error => {
                        console.error("Error getting document content:", error);
                        setErrorOccurred("Error getting document content.");
                        setStatus('error');
                    }
                    );

            })
            .catch(error => {
                console.error("Error getting document:", error);
                setErrorOccurred("Error getting document.");
                setStatus('error');
            }
            );

    }, [getAccessTokenSilently, setUserDoc, setDocumentTitle, setDocContent, setErrorOccurred]),
      status,
    };
};