export const steps = [
    {
      selector: '.analyze-step',
      content: 'Let\'s start with running a \'General Analysis\' on the document. This will help SuperWrite better understand the content and give us insightful suggestions!',
    },
    {
      selector: '.analysis-followup-step',
      content: 'After analysis, SuperWrite will offer some follow-up suggestions. Choose one to get more in-depth information about your document from the AI. This is where the magic happens!',
    },
    {
      selector: '.manual-question-step',
      content: 'Got a question? Type it in here. SuperWrite will search the internet and your other documents to provide an answer. For instance, try asking \'Are there any other similar efforts?\' Let\'s get the conversation going!'
    },
    {
        selector: '.analyze-section-step',
        content: 'Looking to go deeper into a specific paragraph? Select the text and click on the analyze button in the menu. SuperWrite is all about depth!',
    },
    {
        selector: '.analyze-section-step',
        content: 'Have an observation or idea to note down? You can add a comment to any line. Select the text and hit the comment button in the menu.',
    },
    {
        selector: '.share-doc-step',
        content: 'Excited about your findings? You can share the document and the chat with others. Collaboration is key with SuperWrite!',
    },
    {
        selector: '.feedback-step',
        content: 'Your thoughts matter to us! Feel free to leave your feedback here. We\'re always looking to improve SuperWrite to make your experience better!',
    }
]
