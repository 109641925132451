import React, { ReactElement, FC } from "react";
import { Box, Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from '@mui/icons-material/Edit';
import { useHighlight } from "./AnimatedGlowingButton";


const Landing: FC<any> = (): ReactElement => {

    const { loginWithRedirect } = useAuth0();
    const { AnimatedButton, color } = useHighlight();

    return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginLeft: '1em',
                    marginRight: '1em',
                }}
            >
                <Typography variant="h1" component="div" gutterBottom>
                    Boost Insightful Reading
                </Typography>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    margin: '0.6em',
                    fontSize: '4em',
                }}>
                    <Typography variant="h4" component="div" gutterBottom>
                        Are you frustrated with AI-generated documents that lack depth?
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom>
                        Writing isn't about churning out documents. It is a launchpad for thought.
                    </Typography>
                    <Typography variant="h4" component="div" gutterBottom style={{ backgroundColor: color, marginLeft: '0.167em' }}>
                        SuperWrite analyzes documents, unlocking meaningful insights.
                    </Typography>
                </Box>
                <Typography variant="h2" component="div" gutterBottom>
                    Ready to Supercharge Your Doc Analysis with AI?
                </Typography>
                <AnimatedButton
                    variant="contained"
                    size='large'
                    onClick={() => loginWithRedirect()}
                    endIcon={<EditIcon style={{ color: '#FCF9F2', fontSize: '1.2em' }} />}
                >
                    Get SuperWrite
                </AnimatedButton>

            </Box>
    );
}

export default Landing;