import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import { useCallback } from 'react';


mixpanel.init('1983a4b359428fad3a3b23f63de7256c', {
    track_pageview: true,
});

export enum SupportedEvents {
    SignUp = 'SignUp',
    LogIn = 'LogIn',
    TourRequested = 'TourRequested',
    WaitlistSocialHandlesUpdated = 'WaitlistSocialHandlesUpdated',
    SuggestOnboarding = 'SuggestTour',
    HomePageNewDocumentRequested = 'HomePageNewDocumentRequested',
    HomePageExistingDocumentRequested = 'HomePageExistingDocumentRequested',
    MyDocsDeleteADocument = 'MyDocsDeleteADocument',
    NewDocumentCreated = 'NewDocumentCreated',
    DocumentLoaded = 'DocumentLoaded',
    AnalyzeEntireContent = 'AnalyzeEntireContent',
    UserMessageSent = 'UserMessageSent',
    AnalysisRequested = 'AnalysisRequested',
    AnalyzeSection = 'AnalyzeSection',
    TourStarted = 'TourStarted',
    TourInitialAnalysisComplete = 'TourInitialAnalysisComplete',
    TourSuggestedFollowUpAnalysisComplete = 'TourSuggestedFollowUpAnalysisComplete',
    TourManualQuestionAnalysisComplete = 'TourManualQuestionAnalysisComplete',
    TourAnalyzeSectionComplete = 'TourAnalyzeSectionComplete',
    TourShareDialogClosed = 'TourShareDialogClosed',
    TourAddCommentDone = 'TourAddCommentDone',
    ShareButtonClicked = 'ShareButtonClicked',
    AccountPageDeleteAccountClicked = 'AccountPageDeleteAccountClicked',
    AccountPageDeleteAccountConfirmed = 'AccountPageDeleteAccountConfirmed',

}

export default function useLogBIEvent() {
    const { user, isAuthenticated } = useAuth0();

    const logEvent = useCallback((eventType: SupportedEvents, eventProperties?: any) => {
        if (isAuthenticated) {
            try {
                mixpanel.identify(user?.sub);
                mixpanel.track(eventType, eventProperties);    
            } catch (error) {
                console.error("Error logging event:", error);
                // Ignore the exception to keep app working
            }
        }
    }, [isAuthenticated, user]);

    return logEvent;
}
