import React, { FC, ReactElement, useState } from "react";
import { Box, Typography, Button, Alert, CircularProgress } from "@mui/material";
import FeedbackForm from './FeedbackForm';
import { useNavigate } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { useHighlight } from "./AnimatedGlowingButton";
import { useCreateDocument } from "../hooks/docs/useCreateDocument";
import { sampleDoc } from "../utils/onboardingSampleDoc";
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';

interface Props {
    suggestOnboarding: boolean;
}

const WelcomeReturningUsers: FC<Props> = ({ suggestOnboarding }): ReactElement => {
    const navigate = useNavigate();
    const { currentStep, setCurrentStep } = useTour();
    const logBIEvent = useLogBIEvent();

    const getRandomGreeting = (isNewUser: boolean, incompleteTour: boolean): string => {
        const greetingsReturning = [
            "Good to see you again at SuperWrite!",
            "Look who's back! Ready to get creative?",
            "Yay, you're back! SuperWrite missed you!",
            "Ready for another day of awesome writing at SuperWrite?",
            "Welcome back to SuperWrite! Let's make today a productive one."
        ];

        const greetingsNew = [
            "Welcome to SuperWrite! Have you tried the quick feature tour yet?",
            "Hi there, new friend! Start your journey with a quick feature tour!",
            "Welcome to SuperWrite! Explore the possibilities with our quick feature guide.",
            "New to SuperWrite? Get the most out of our site with a brief tour.",
            "First time at SuperWrite? Uncover handy features with our guided tour!"
        ];

        const greetingsIncompleteTour = [
            "Good to see you! Don't forget to complete your feature tour.",
            "Welcome back! Your feature tour is waiting for you.",
            "Hey there, eager to finish your tour?",
            "Just a few steps left to complete your tour, are you ready?",
            "Back for more? Let's wrap up that feature tour!"
        ];

        let greetings;

        if (isNewUser) {
            greetings = greetingsNew;
        } else if (incompleteTour) {
            greetings = greetingsIncompleteTour;
        } else {
            greetings = greetingsReturning;
        }

        return greetings[Math.floor(Math.random() * greetings.length)];
    }


    const getRandomSalutation = (): string => {
        const salutations = [
            "Hi",
            "Hello",
            "Hey",
            "Hola",
            "Howdy",
            "Hiya",
            "Yo",
            "Hey there",
        ];

        return salutations[Math.floor(Math.random() * salutations.length)];
    }

    const { AnimatedButton } = useHighlight();

    const [errorOccured, setErrorOccured] = useState<string | null>(null);
    const [isCreatingDocument, setIsCreatingDocument] = useState<boolean>(false);

    const createDocument = useCreateDocument(null, setErrorOccured);

    const handleTourClick = async () => {

        // Log tour event
        logBIEvent(SupportedEvents.TourRequested);

        setIsCreatingDocument(true);

        await createDocument(sampleDoc).then((doc) => {
            // Navigate to doc page for sample
            navigate(`/doc/${doc!._id}?tour=true`);
        }).catch((error) => {
            console.error("Error creating document:", error);
            setErrorOccured("Error creating document. Please try again later.");
        }).finally(() => {
            setIsCreatingDocument(false);
            setCurrentStep(0); // Always start from beginning
        }
        );

    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                maxWidth: '60rem',
                padding: '4em',
                margin: 'auto',
                backgroundColor: '#fff', // white background
                boxShadow: 5, // apply drop shadow
                borderRadius: '8px',
            }}
        >
            <Typography variant="h1" component="div" gutterBottom className="first-step">
                {getRandomSalutation()}! 👋
            </Typography>
            <Typography variant="h2" component="div" gutterBottom>
                {getRandomGreeting(suggestOnboarding, currentStep !== 0)}
            </Typography>

            {errorOccured && <Alert severity='error' sx={{ margin: '1em' }}>{errorOccured}</Alert>}

            {
                suggestOnboarding && (
                    <AnimatedButton variant="contained" color="secondary" style={{ margin: '1em' }} 
                        disabled={isCreatingDocument}
                        onClick={() => handleTourClick()}>
                            Take a Tour
                        {!isCreatingDocument && <span style={{ marginLeft: '1em' }}>🚀</span>}
                        {
                            isCreatingDocument && <CircularProgress sx={{marginLeft: '1em'}} />
                        }
                    </AnimatedButton>
                )
            }
            <Box sx={{ margin: '2em' }}>
                <Typography variant="h5" component="div" gutterBottom sx={{ marginBottom: '1em' }}>
                    {suggestOnboarding
                        ? "Ready to start a new document?"
                        : "What would you like to do today? Start a new document or continue with your existing work?"}
                </Typography>
                <Button variant="contained" color="primary" onClick={() => {
                    navigate('/doc/new');
                    logBIEvent(SupportedEvents.HomePageNewDocumentRequested);
                } }>
                    Start New Document
                </Button>
                {!suggestOnboarding && (
                    <Button variant="contained" color="secondary" style={{ marginLeft: '10px' }} onClick={() => { 
                        navigate('/mydocs'); 
                        logBIEvent(SupportedEvents.HomePageExistingDocumentRequested);
                        }}>
                        Continue with Existing Document
                    </Button>
                )}
            </Box>



            {!suggestOnboarding && (

                <Box sx={{ margin: '2em' }}>
                    <Typography variant="h2" component="div" gutterBottom>
                        Submit feedback
                    </Typography>

                    <FeedbackForm />
                </Box>
            )}
        </Box>
    );
};

export default WelcomeReturningUsers;
