import React, { ReactElement, FC, useCallback, useEffect } from "react";
import Landing from "../components/Landing"
import { LicenseResponse } from "../interfaces/APIResponses";
import { useAuth0 } from "@auth0/auth0-react";
import { Alert, CircularProgress, Box } from "@mui/material";
import Waitlist from "../components/Waitlist";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { useGetAccount, useCreateUpdateAccount } from '../hooks/accountHooks';
import WelcomeReturningUsers from "../components/WelcomeReturningUsers";
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';
import { useNavigate } from "react-router-dom";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const Home: FC<any> = (): ReactElement => {

    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [licenseDenied, setLicenseDenied] = React.useState<boolean>(false);
    const [errorOccured, setErrorOccured] = React.useState<string>('');
    const [checkingLicense, setCheckingLicense] = React.useState<boolean>(false);
    const [suggestOnboarding, setSuggestOnboarding] = React.useState<boolean>(false);

    const getAccount = useGetAccount();
    const createUpdateAccount = useCreateUpdateAccount();

    const logBIEvent = useLogBIEvent();
    const navigate = useNavigate();

    React.useEffect(() => {

        const checkAndCreateAccount = async () => {
            console.log("Checking if account exists");
            try {
                let account = await getAccount();
                console.log("Account:", account);
                if (!account) {
                    // If there is no account, create one with default values
                    console.log("No account found, creating one");
                    account = await createUpdateAccount('', '');
                    console.log("Account created");

                    logBIEvent(SupportedEvents.SignUp);
                } else {
                    logBIEvent(SupportedEvents.LogIn);
                }

                // Check if account was created in last 24 hours.
                const accountCreatedRecently = new Date(account!.createdAt).getTime() > Date.now() - 48 * 60 * 60 * 1000;
                if (accountCreatedRecently) {
                    console.log("Recent account");

                    localStorage.setItem('suggestOnboarding', 'true');
                    setSuggestOnboarding(true);

                    // Log BI event
                    logBIEvent(SupportedEvents.SuggestOnboarding);
                }
                
            } catch (error: any) {
                // Handle the error
                console.error("Error occurred while checking or creating account: ", error);
                setErrorOccured("Error while getting account information. Please try again later.");
            }
        };

        if (isAuthenticated) {
            checkAndCreateAccount();
        }
    }, [getAccount, createUpdateAccount, setErrorOccured, isAuthenticated, logBIEvent]); // Ensure effect runs when these dependencies change


    const getLicense = useCallback(async () => {

        if (!isAuthenticated) {
            console.log("Not authenticated, skipping getting license");
            return;
        }

        console.log('Getting license using Access Token');

        setCheckingLicense(true);

        const accessToken = await getAccessTokenSilently();

        // Make a GET to /license endpoint.
        fetch(`${BACKEND_API}/license`, {
            headers: {
                Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
            }
        })
            .then(response => {
                if (response.status === 403) {
                    return {
                        denied: true
                    };
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data: LicenseResponse) => {
                console.log("Got license:", data);
                setLicenseDenied(data.denied);
            })
            .catch(error => {
                console.error("Error getting license:", error);
                setErrorOccured("Error getting license. Please retry later.");
                setLicenseDenied(true);
            }).finally(() => {
                setCheckingLicense(false);
            });
    }, [isAuthenticated, getAccessTokenSilently, setLicenseDenied, setErrorOccured, setCheckingLicense]);

    React.useEffect(() => {
        getLicense();
    }, [getLicense]);

    let theme = createTheme({

        typography: {
            fontFamily: [
                'Cal Sans', 'Roboto', 'Arial', 'sans-serif'
            ].join(','),
            h1: {
                fontSize: '6em',
                color: '#009BF3',
            },
            h2: {
                color: '#404040'
            },
            h3: {
                color: '#404040'
            },
            h4: {
                color: '#404040'
            },
            h5: {
                color: '#404040'
            },
            h6: {
                color: '#404040'
            },
        },
        palette: {
            background: {
                default: '#fcf9f2', // this sets the default background color
            },
            primary: {
                main: '#009bf3',
            },
            secondary: {
                main: '#603be0',
            },
        },
        shape: {
            borderRadius: 12,
        },
    });

    theme = responsiveFontSizes(theme);

    // Redirect to /waitlist if license is denied.
    useEffect(() => {
        if (licenseDenied) {
            navigate('/waitlist');
        }
    }, [licenseDenied, navigate]);

    return (
        <ThemeProvider theme={theme}>
            {errorOccured && <Alert severity='error' sx={{ margin: '1em' }}>{errorOccured}</Alert>}
            {/* Show the Landing page if the user is not signed in*/}
            {!isAuthenticated && <Landing />}
            {checkingLicense && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',  // adjust as needed
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            {!checkingLicense && licenseDenied && !errorOccured && <Waitlist />}
            {isAuthenticated && !checkingLicense && !licenseDenied && !errorOccured && <WelcomeReturningUsers suggestOnboarding={suggestOnboarding} />}
        </ThemeProvider>
    );

}

export default Home;