import React from 'react';
import { Container, Box } from '@mui/material';
import NormalMarkdownRenderer from '../components/NormalMarkdownRenderer';

function PrivacyPolicy() {
    return (
        <Container>
            <Box
                sx={{
                    flexGrow: 1,
                    marginLeft: {
                      xs: '1em',  // for screens 0px and up
                      sm: '2em',  // for screens 600px and up
                      md: '3em',  // for screens 900px and up
                      lg: '5em',  // for screens 1200px and up
                      xl: '5em',  // for screens 1536px and up
                    },
                    boxShadow: 5, // apply drop shadow
                    backgroundColor: '#fff', // white background
                    padding: {
                      xs: '1em',  
                      sm: '2em',  
                      md: '3em',  
                      lg: '5em',  
                      xl: '5em',  
                    }, // padding inside the box
                    borderRadius: '8px',
                  }}
                >
                <NormalMarkdownRenderer>{`# SuperWrite Privacy Policy
_Last updated: Jul 27, 2023_

<br />
Hello there! 🙋‍♀️
<br /><br />
Welcome to SuperWrite! Privacy is crucial to us, as we are a company dealing with user data and analyzing documents. We understand the importance of safeguarding your data.
<br /><br />
SuperWrite ("we", "us", or "our") provides AI-based document analysis solutions. This Privacy Policy outlines our practices for collecting, using, maintaining, protecting, and disclosing your information. If anything is unclear, please don't hesitate to [reach out](mailto:hello@getsuperwrite.com) to us. 

## Our Promise 🤞

Your privacy is not just an obligation for us, but it's at the core of our mission to provide a secure document analysis platform. We handle your sensitive information responsibly, ensuring its safety and privacy while delivering our effective and innovative features.
<br /><br />
We believe in transparency and healthy relationships. That's why we're committed to being open about our data practices, empowering you to make informed decisions about your data's use within SuperWrite.
<br /><br />
## Why we Store Data 📦

Storing data is critical to offer you reliable, safe, and user-friendly services. Here's why:

- **Enhanced Accessibility**: Storing data on secure servers allows you to access your documents across multiple devices, enhancing user experience.
- **Data Protection**: With server-based storage, we can ensure data safety. Even if you lose access to a device, your documents won't be permanently lost.
- **Reliability**: We utilize MongoDB for our database needs, a widely trusted database management system, and store data in secure services provided by Amazon AWS. Both these services adhere to the highest security standards, ensuring that your data is secured both in transit and at rest. Please refer to the privacy and security policies of these services for more information.

## Information We Collect 🗂️

To provide our services, we may collect:

- **User-Provided Information**: You provide us with personally identifiable information, such as your email address when you register for an account or use our services.
- **Automatically Collected Information**: We collect certain information about the device you use to access our services, like device information, IP address, and browser type.

## Use of Information 💡

We use the collected information for several purposes:

- **Providing Our Services**: We use your account information to allow access to SuperWrite, and we use your document data to provide analysis.
- **Maintaining Our Services**: We use technical information about your devices and interactions to resolve issues, ensure compatibility, and keep our services up and running.
- **Improving Our Services**: We use aggregated and anonymized data to identify trends, understand user behavior, and optimize the user experience.

In all instances, we uphold strict data handling practices to ensure your information is handled responsibly.

## User Rights and Control 🛡️

You have the right to access, update, and delete your personal information at any time. To delete your data, go to 'Account Settings' and click on 'Delete My Account'. Please note that deleted data may be kept in backups for a period of time, but will not be available to others."

## Third-Party Services 🤝

We utilize various third-party services to help us provide, improve, protect, and promote our services. These third-party service providers have their own privacy policies that dictate how they use such information.

## Non-Disclosure of Sensitive Document Data 🛡️

We are committed to safeguarding your sensitive document data. We do not share, sell, or distribute your sensitive data to any third parties unless absolutely required by law.

## Security 🔐

We employ a variety of measures to safeguard your information. All data transmitted between you and our services is secured using strong encryption protocols. We implement robust access control measures at the managerial level. We work with independent third-party experts to audit our security infrastructure and practices.

## Changes to This Privacy Policy 🔁

We reserve the right to modify this privacy policy at any time. If we make material changes to this policy, we may notify you here, by email, or by means of a notice on our home page.

## Contact Us 💌

If you have any questions regarding this Privacy Policy or the practices of this Site, please [contact us](mailto:hello@getsuperwrite.com).

`}</NormalMarkdownRenderer>
            </Box>
        </Container>
    );
}

export default PrivacyPolicy;
