/* eslint-disable import/no-extraneous-dependencies */
import { getMarkRange, Mark, mergeAttributes } from '@tiptap/react';
import { Plugin, TextSelection } from 'prosemirror-state';

// Article from author on how this works: https://dev.to/sereneinserenade/how-i-implemented-google-docs-like-commenting-in-tiptap-k2k
// Source: https://github.com/sereneinserenade/tiptap-comment-extension-react/tree/main

/**
 * Issues:
 * - If the user selected multiple paragraphs + heading, etc., then a comment mark per each of those pieces is being created.
 *   This causes the icon after comment to appear awkwardly.
 * - When you click inside the bubble-menu, the comment selection disappears so you don't know what you are commenting.
 * - Clicking on a comment-mark is not loading the comments on right.
 */

export interface CommentOptions {
  HTMLAttributes: Record<string, any>,
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      /**
       * Set a comment mark
       */
      setComment: (comment: string) => ReturnType,
      /**
       * Toggle a comment mark
       */
      toggleComment: () => ReturnType,
      /**
       * Unset a comment mark
       */
      unsetComment: () => ReturnType,
    }
  }
}

export const Comment = Mark.create<CommentOptions>({
  name: 'comment',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      comment: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-comment'),
        renderHTML: (attrs) => ({ 'data-comment': attrs.comment }),
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-comment]',
        getAttrs: (el) => !!(el as HTMLSpanElement).getAttribute('data-comment')?.trim() && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addCommands() {
    return {
      setComment: (comment: string) => ({ commands }) => commands.setMark('comment', { comment }),
      toggleComment: () => ({ commands }) => commands.toggleMark('comment'),
      unsetComment: () => ({ commands }) => commands.unsetMark('comment'),
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleClick(view, pos) {
            const { schema, doc, tr } = view.state;

            const range = getMarkRange(doc.resolve(pos), schema.marks.comment);

            if (!range) return false;

            const [$start, $end] = [doc.resolve(range.from), doc.resolve(range.to)];

            view.dispatch(tr.setSelection(new TextSelection($start, $end)));

            return true;
          },
        },
      }),
    ];
  },
});

export default Comment;