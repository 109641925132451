import React, { FC, useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Box, Avatar, Badge, Tooltip, CircularProgress, Alert } from '@mui/material';
import { useGetAccount, useDeleteAccount } from '../hooks/accountHooks';
import { AccountInfo } from '../interfaces/APIResponses';
import FeedbackForm from '../components/FeedbackForm';
import { useAuth0 } from "@auth0/auth0-react";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';

export const Account: FC = () => {
    const getAccount = useGetAccount();
    const [account, setAccount] = useState<AccountInfo | null>(null);
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const { deleteAccount, status } = useDeleteAccount();
    const { isAuthenticated, logout } = useAuth0();
    const [accountNotFound, setAccountNotFound] = useState<boolean>(false);
    const logBIEvent = useLogBIEvent();

    const fetchAccountInfo = useCallback(async () => {
        const accountData = await getAccount();
        setAccount(accountData);

        if (accountData === null) {
            setAccountNotFound(true);
        }

    }, [getAccount, setAccount]);

    const handleOpenDialog = () => {
        setOpenDialog(true);

        // Log BI event
        logBIEvent(SupportedEvents.AccountPageDeleteAccountClicked);
    }
    const handleCloseDialog = () => setOpenDialog(false);

    const handleDeleteAccount = async () => {
        const deleteConfirmation = await deleteAccount();
        handleCloseDialog();
        if (deleteConfirmation) {
            // Log BI event
            logBIEvent(SupportedEvents.AccountPageDeleteAccountConfirmed);

            logout({ returnTo: window.location.origin } as any);
        }
    }

    React.useEffect(() => {
        if (isAuthenticated) {
            fetchAccountInfo();
        }
    }, [fetchAccountInfo, isAuthenticated]);

    if (!account && !accountNotFound) {
        return <div>Loading...</div>;
    }

    let theme = createTheme({

        typography: {
            fontFamily: [
                'Cal Sans', 'Roboto', 'Arial', 'sans-serif'
            ].join(','),
            h1: {
                fontSize: '6em',
                color: '#009BF3',
            },
            h2: {
                color: '#404040'
            },
            h3: {
                color: '#404040'
            },
            h4: {
                color: '#404040'
            },
            h5: {
                color: '#404040'
            },
            h6: {
                color: '#404040'
            },
        },
        palette: {
            background: {
                default: '#fcf9f2', // this sets the default background color
            },
            primary: {
                main: '#009bf3',
            },
            secondary: {
                main: '#603be0',
            },
        },
        shape: {
            borderRadius: 12,
        },
    });

    theme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                {accountNotFound && <Alert severity="error">Account not found. Please try logging out and back in.</Alert>}
                {account && <>
                <Box sx={{
                    display: 'inline-block',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '4em',
                    bgcolor: '#fff',
                    borderRadius: '8px',
                    boxShadow: 4,
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip title="SuperHero: Early adopter shaping the future of SuperWrite" placement="top">
                            <Badge badgeContent="SuperHero 🚀" 
                                color="secondary" overlap="circular">
                                <Avatar alt={account!.email} src={account!.pictureUrl} 
                                    sx={{ width: '10rem', height: '10rem', marginRight: '2rem', boxShadow: 4 }} />
                            </Badge>
                        </Tooltip>

                        <Typography variant="h2">{account!.email}</Typography>
                    </Box>
                    {status === 'error' && <Alert sx={{ marginTop: '2em'}} severity="error">There was an error deleting your account. Please try again.</Alert>}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2em' }}>
                <Button variant="contained" color="error" onClick={handleOpenDialog} disabled={status === 'pending'}>
                    {status === 'pending' ? <CircularProgress size={24} /> : 'Delete My Account'}
                </Button>
            </Box>
                </Box>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                    <DialogTitle>Confirm Account Deletion</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            We're sorry to see you go. If you have a moment, please let us know why you're leaving.
                        </DialogContentText>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', margin: '2em' }}>
                            <FeedbackForm />
                        </Box>
                        <DialogContentText color="error">
                            Please note, deleting your account will permanently erase all of your data and cannot be undone. Make sure you've saved any important data before continuing.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancel</Button>
                        <Button onClick={handleDeleteAccount} color="error" disabled={status === 'pending'}>
                            {status === 'pending' ? <CircularProgress size={24} /> : 'Delete My Account'}
                        </Button>
                    </DialogActions>
                </Dialog>
                </>
                }
            </Box>
        </ThemeProvider>



    );
};

export default Account;
