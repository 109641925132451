import React, { useState, useCallback } from "react";
import { TextField, Typography, Box, Rating, Alert, Button } from "@mui/material";
import { useSubmitFeedback } from "../hooks/useSubmitFeedback";
import { useTheme } from "@mui/material/styles";

const FeedbackForm: React.FC = () => {
    const [feedbackText, setFeedbackText] = useState('');
    const [starRating, setStarRating] = useState<number | null>(null);
    const [submitStatus, setSubmitStatus] = useState<"idle" | "submitting" | "success" | "error">("idle");
    const theme = useTheme();
    const submitFeedback = useSubmitFeedback();

    const handleSubmit = useCallback(async () => {
        try {
            setSubmitStatus("submitting");
            await submitFeedback({ text: feedbackText, starRating: starRating || 0 });
            setSubmitStatus("success");
            setFeedbackText('');
            setStarRating(null);
        } catch (error: any) {
            setSubmitStatus("error");
        }
    }, [feedbackText, starRating, submitFeedback]);

    return (
        <Box sx={{ 
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                maxWidth: '50rem',
                margin: 'auto',
            }}>
            {submitStatus === "success" && <Alert severity="success">Feedback submitted successfully!</Alert>}
            {submitStatus === "error" && (
                <Alert severity="error">
                    There was an error submitting your feedback. Please try again.
                </Alert>
            )}
            <Typography variant="body1" component="div" gutterBottom sx={{marginBottom: '1em'}}>
            We appreciate any feedback you might have to help us improve
            </Typography>
            <TextField
                label="Feedback"
                multiline
                rows={4}
                variant="outlined"
                value={feedbackText}
                onChange={e => setFeedbackText(e.target.value)}
                required
                fullWidth
                sx={{ backgroundColor: theme.palette.background.default }} 
            />
            <Box component="fieldset" sx={{ borderColor: 'transparent', mt: 2 }}>
                <Typography component="legend">Likelihood to recommend SuperWrite</Typography>
                <Rating
                    name="star-rating"
                    value={starRating}
                    precision={1}
                    size="large"
                    onChange={(event, newValue) => {
                        setStarRating(newValue);
                    }}
                />
            </Box>
            <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSubmit}
                disabled={submitStatus === "submitting"}
            >
                Submit
            </Button>
        </Box>
    );
};

export default FeedbackForm;
