import { Paper, Box, Typography } from '@mui/material';
import { styled } from "@mui/system";
import Avatar from '@mui/material/Avatar';
import { FC } from 'react';
import { formatDistanceToNow } from 'date-fns';

interface UserBubbleProps {
    message: string;
    senderEmail: string;
    senderPictureUrl: string;
    createdAt: Date;
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginLeft: 0,
    alignSelf: 'flex-end',
    maxWidth: '90%',
    wordWrap: 'break-word',
}));

export const UserBubble: FC<UserBubbleProps> = ({ message, senderEmail, senderPictureUrl, createdAt }) => (

    <Box display="flex" flexDirection="row" alignItems="flex-start" sx={{ marginTop: '0.5em' }}>
        <Box display="flex" flexDirection="column" alignItems="center" mr={2}>
            <Avatar alt={senderEmail} src={senderPictureUrl} sx={{boxShadow: 2}} />
        </Box>
        <Box>
            <Box display="flex" width="100%">
                <Typography variant="caption" sx={{ marginRight: '1em', fontWeight: 'bold' }}>{senderEmail}</Typography>
                <Typography variant="caption">{createdAt ? formatDistanceToNow(new Date(createdAt)) : "a while"} ago</Typography>
            </Box>
            <StyledPaper>
                <Typography variant="body1">{message}</Typography>
            </StyledPaper>
        </Box>
    </Box>
);




export const BotBubble = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    // color: theme.palette.secondary.contrastText,
    padding: theme.spacing(1.5),
    margin: theme.spacing(1.5),
    alignSelf: 'flex-end',
    maxWidth: '90%',
    wordWrap: 'break-word',
}));

export const InfoBubble = styled(Paper)(({ theme }) => ({
    color: theme.palette.text.primary,
    padding: theme.spacing(0),
    marginLeft: theme.spacing(1), // Align with the bot bubble
    alignSelf: 'flex-start',
    maxWidth: '80%',
    wordWrap: 'break-word',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    fontSize: '0.8em',
}));