import { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { UserDoc } from '../../interfaces/APIResponses';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const useUserDocuments = () => {
  // The documents state.
  const [documents, setDocuments] = useState<UserDoc[]>([]); 
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { getAccessTokenSilently } = useAuth0();

  const fetchDocuments = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();

      const response = await fetch(`${BACKEND_API}/docs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();

      setDocuments(responseData);  // set your data into state
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchDocuments();
  }, [fetchDocuments]);

  return { documents, isLoading, error, refetch: fetchDocuments };
};

export default useUserDocuments;
