import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';

interface CommentInputProps {
    onAddComment: (commentText: string) => void;
}

const CommentInput: React.FC<CommentInputProps> = ({ onAddComment }) => {
    const [commentText, setCommentText] = useState('');

    const handleAddComment = () => {
        if (!commentText.trim()) return;
        onAddComment(commentText);
        setCommentText('');
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleAddComment();
        }
    };

    return (
        <Box component="form">
            <TextField
                autoFocus
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
                onKeyDown={handleKeyDown}
                multiline
                placeholder="Add comment..."
                fullWidth
            />

            <Box display="flex" justifyContent="flex-end" mt={1}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleAddComment}
                    disabled={!commentText.trim()}
                    style={{ marginRight: '1rem' }}
                >
                    Add
                </Button>

                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => setCommentText('')}
                    disabled={!commentText.trim()}
                >
                    Clear
                </Button>
            </Box>
        </Box>
    );
};

export default CommentInput;
