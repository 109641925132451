import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";
import { DocRequest, UserDoc } from "../../interfaces/APIResponses";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export const useUpdateDocument = (setUserDoc: Function, setErrorOccurred: Function, userDoc: UserDoc | null) => {
    const { getAccessTokenSilently } = useAuth0();

    return useCallback(async (docToUpdate: DocRequest) => {

        const accessToken = await getAccessTokenSilently();

        console.log("Updating document:", docToUpdate);

        // Make an HTTP PUT to /docs endpoint.
        fetch(`${BACKEND_API}/docs/${userDoc!._id}`, {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${accessToken}`, // Include the access token in the request headers
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(docToUpdate)
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data: UserDoc) => {
                console.log("Created/updated document:", data);
                setUserDoc(data);
            })
            .catch(error => {
                console.error("Error creating/updating document:", error);
                setErrorOccurred("Error creating/updating document.");
            }
            );


    }, [getAccessTokenSilently, setUserDoc, setErrorOccurred, userDoc]);
};
