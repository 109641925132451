import { useCallback } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { FeedbackRequest } from '../interfaces/APIResponses';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

export function useSubmitFeedback() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const submitFeedback = useCallback(async (feedback: FeedbackRequest): Promise<void> => {
        if (!isAuthenticated) {
            console.log("Not authenticated, skipping feedback submission");
            return;
        }

        console.log('Submitting feedback using Access Token');

        const accessToken = await getAccessTokenSilently();

        try {
            const response = await fetch(`${BACKEND_API}/feedback`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}` // Include the access token in the request headers
                },
                body: JSON.stringify(feedback)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            console.log("Feedback submitted successfully");

        } catch (error) {
            console.error("Error submitting feedback:", error);
            throw error;
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    return submitFeedback;
}

export default useSubmitFeedback;