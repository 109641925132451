import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, CardMedia, Button, Typography, CircularProgress, Alert, Tooltip, Fab, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import useUserDocuments from '../hooks/docs/useUserDocuments';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useAuth0 } from "@auth0/auth0-react";
import useLogBIEvent, { SupportedEvents } from '../utils/biEvents';

const BACKEND_API = process.env.REACT_APP_BACKEND_API;

const MyDocs: React.FC = () => {
    const { documents, isLoading, error, refetch } = useUserDocuments();
    const [open, setOpen] = useState(false);
    const [selectedDoc, setSelectedDoc] = useState<string | null>(null);
    const { getAccessTokenSilently } = useAuth0();
    const logBIEvent = useLogBIEvent();

    const handleOpen = (docId: string) => {
        setOpen(true);
        setSelectedDoc(docId);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async () => {
        const token = await getAccessTokenSilently();
        fetch(`${BACKEND_API}/docs/${selectedDoc}`, {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`
            },
        })
            .then(response => response.json())
            .then(async () => {
                // Refresh the documents
                await refetch();
            })
            .catch(error => {
                console.log(error); // Handle the error here
            })
            .finally(() => {
                // Log BI event
                logBIEvent(SupportedEvents.MyDocsDeleteADocument);
            });
        handleClose();
    };


    return (
        <Box sx={{ margin: '2em', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            {error && <Alert severity='error' sx={{ margin: '1em' }}>{error}</Alert>}
            {isLoading && <CircularProgress />}
            {(!isLoading) && (!error) && documents.length === 0 && <Typography variant="h5" component="div" sx={{ margin: '1em' }}>No documents found. Create one!</Typography>}
            <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                {documents.map((doc) => (
                    <Grid
                        sx={{ margin: '1em' }}
                        item xs={12} sm={6} md={4} lg={2}
                        key={doc._id}>
                        <Card sx={{ width: '100%' }}>
                            <CardMedia
                                component="img"
                                height="120"
                                image={doc.s3DocThumbnailKey ? `https://${doc.s3DocThumbnailBucket}.s3.amazonaws.com/${doc.s3DocThumbnailKey}` : '/doc_thumbnail.png'}
                                alt="Document thumbnail"
                            />
                            <CardContent>
                                <Tooltip title={doc.title} placement="top">
                                    <Typography gutterBottom variant="h5" component="div"
                                        sx={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: 2,
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }}>
                                        {doc.title}
                                    </Typography>
                                </Tooltip>

                                <Typography variant="body2" color="text.secondary">
                                    Created {doc.createdAt ? formatDistanceToNow(new Date(doc.createdAt)) : "a while"} ago
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Updated {doc.updatedAt ? formatDistanceToNow(new Date(doc.updatedAt)) : "a while"} ago
                                </Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1em' }}>
                                    <Button variant='outlined' size="small" onClick={() => window.location.href = `/doc/${doc._id}`}>Open</Button>

                                    <IconButton onClick={() => handleOpen(doc._id)} edge="end" aria-label="delete">
                                        <DeleteOutlineIcon />
                                    </IconButton>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            <Tooltip title="Create new document" placement="left">
                <Fab color="primary" aria-label="add" sx={{ position: 'fixed', bottom: '10em', right: '2em' }} onClick={() => window.location.href = '/doc/new'}>
                    <AddIcon />
                </Fab>
            </Tooltip>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Document"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this document? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDelete} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default MyDocs;
